import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import DisplayStatus from '../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalDcas from './ModalDcas'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ModalNovaLicenca from './ModalNovaLicenca'
import ModalHabilitaMonitorToner from './Components/ModalHabilitarMonitoramentoToner'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const LicencasCollector = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [licencasCollector, setLicencasCollector] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);
    const [ registros, setRegistros] = useState(0)
    const refDataCadastro = React.useRef();
    const refChaveLicenca = React.useRef();
    const refRazaoSocial = React.useRef();
    

    useEffect(()=>{
        getLicencasCollector();
       
    }, [page])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])

    


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getLicencasCollector = () =>{
        
        setLoading(true)
        Conexao.post('dcaController/getLicencasCollector?idempresa='+props.match.params.chaveEmpresa, {
            idUsuario : props.match.params.idempresa, 
            idEmpresa : props.match.params.chaveEmpresa,             
          
            dataCadastro : refDataCadastro.current ? refDataCadastro.current.value : '', 
            chaveLicenca : refChaveLicenca.current ? refChaveLicenca.current.value : '', 
            razaoSocial : refRazaoSocial.current ? refRazaoSocial.current.value : '', 
            limit: rowsPerPage,
            offSet :  page
        }).then(response =>{
            
            if(response.data.status == 'OK'){
                setLicencasCollector(response.data.payload.dados);
                setRegistros(response.data.payload.registros);
           
               
            }else{
                setLicencasCollector([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }

   

    return <Principal atualizar = {loading}>
    <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Gerenciar Licenças do Collector</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>

            <Grid item xs = {3}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef = {refDataCadastro}        
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Chave de Instalação'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    inputRef ={refChaveLicenca}        
                   />
            </Grid>
            <Grid item xs = {5}>
                <TextField
                    label ='Razão Social'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    inputRef={refRazaoSocial}               
                   />
            </Grid>
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getLicencasCollector()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{
                    refDataCadastro.current.value ='';
                    refChaveLicenca.current.value ='';
                    refRazaoSocial.current.value ='';
                    getLicencasCollector();
                }}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Gerenciar licenças collector</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-sm' style ={{fontSize:'12px'}}>
            <TableHead>
                <TableCell align='center' style = {{width:"5%",fontSize:'12px'}}>ID</TableCell>
                <TableCell align='left' style = {{width:"10%",fontSize:'12px'}}>Chave de Instalação</TableCell>
                <TableCell align='left' style = {{width:"45%",fontSize:'12px'}}>Razão Social</TableCell>
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Data Criação</TableCell>                
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Coletores (DCA)</TableCell>
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Monitorar Toner</TableCell>
                <TableCell align='center' style = {{width:"5%",fontSize:'12px'}}>Status</TableCell>
            </TableHead>

            <TableBody>
                
                {
                    licencasCollector && licencasCollector.map(function(licenca, i){
                        return <TableRow key ={i}>
                            <TableCell align='center' style ={{fontSize:'12px'}}>{licenca.idcliente}</TableCell>
                        <TableCell align='left' style ={{fontSize:'12px'}}>{licenca.chave}</TableCell>
                        <TableCell align='left' style ={{fontSize:'12px'}} >{licenca.razaosocial}</TableCell>
                        <TableCell align='center' style ={{fontSize:'12px'}}>{licenca.datacriacao}</TableCell>    
                        <TableCell align='center' style ={{fontSize:'12px'}}>
                            <ModalDcas chave={licenca.chave} Conexao={Conexao} idusuario={licenca.idusuario} idempresa={licenca.idempresa} idcliente={licenca.idcliente} /></TableCell>                  
                        <TableCell align='center' style ={{fontSize:'12px'}} >
                                <ModalHabilitaMonitorToner getData={()=>getLicencasCollector()} licenca={licenca}/>
                    {licenca.monitorartoner == 't' ? <span style ={{fontSize:"9px"}}>ATIVO</span> : <span style ={{fontSize:"9px"}}>INATIVO</span> }
                        </TableCell>
                        <TableCell align='center' style ={{fontSize:'12px'}} >
                            {licenca.ativo == 't' ? <React.Fragment><CheckCircleOutlineIcon style = {{color:"#00695c", cursor:"pointer"}} onClick = {()=>{
                            Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja modificar o status desta licença ?', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true, 
                                cancelButtonText :'Cancelar',    
                                icon :'question'                             
                            }).then(resposta=>{
                                if(resposta.isConfirmed){
                                    Conexao.post('dcaController/desativarLicencaCollector',{
                                        idempresa : licenca.idempresa,
                                        idcliente : licenca.idcliente, 
                                        idlicenca : licenca.idlicencacollector
                                    }).then(response=>{
                                        if(response.data.status.trim() == 'OK'){
                                            Swal.fire({
                                                title :'Printerconnect', 
                                                text : 'Registro atualizado com sucesso', 
                                                showConfirmButton : false, 
                                                showCancelButton : false,  
                                                icon :'success', 
                                                timer : 1500  
                                               });
                                               getLicencasCollector();
                                        }else{
                                            Swal.fire({
                                                title :'Printerconnect', 
                                                text : 'Não foi possível atualizar', 
                                                showConfirmButton : false, 
                                                showCancelButton : false,  
                                                icon :'danger', 
                                                timer : 1500  
                                               });
                                        }
                                       
                                    }).catch(error=>{
                                        Swal.fire({
                                            title :'Printerconnect', 
                                            text : 'Registro atualizado com sucesso', 
                                            showConfirmButton : false, 
                                            showCancelButton : false,  
                                            icon :'error', 
                                            timer : 1500  
                                           });
                                    })
                                }
                            })
                        }}/><br/><span style ={{fontSize:"9px"}}>ATIVO</span></React.Fragment>:<React.Fragment><RadioButtonUncheckedIcon style = {{color:"#b71c1c", cursor:"pointer"}} onClick = {()=>{
                            Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja modificar o status desta licença ?', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true, 
                                cancelButtonText :'Cancelar',    
                                icon :'question'                             
                            }).then(resposta=>{
                                if(resposta.isConfirmed){
                                    Conexao.post('dcaController/ativarLicencaCollector',{
                                        idempresa : licenca.idempresa,
                                        idcliente : licenca.idcliente, 
                                        idlicenca : licenca.idlicencacollector
                                    }).then(response=>{
                                        if(response.data.status.trim() == 'OK'){
                                            Swal.fire({
                                                title :'Printerconnect', 
                                                text : 'Registro atualizado com sucesso', 
                                                showConfirmButton : false, 
                                                showCancelButton : false,  
                                                icon :'success', 
                                                timer : 1500  
                                               });
                                               getLicencasCollector();
                                        }else{
                                            Swal.fire({
                                                title :'Printerconnect', 
                                                text : 'Não foi possível atualizar', 
                                                showConfirmButton : false, 
                                                showCancelButton : false,  
                                                icon :'danger', 
                                                timer : 1500  
                                               });
                                        }
                                       
                                    }).catch(error=>{
                                        Swal.fire({
                                            title :'Printerconnect', 
                                            text : 'Registro atualizado com sucesso', 
                                            showConfirmButton : false, 
                                            showCancelButton : false,  
                                            icon :'error', 
                                            timer : 1500  
                                           });
                                    })
                                }
                            })
                        }}/><br/><span style ={{fontSize:"9px"}}>INATIVO</span></React.Fragment>}
                            
                           </TableCell>
                    </TableRow>
                    })
                }
               
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    <div className='fabIncluir'>
    <ModalNovaLicenca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>getLicencasCollector()}/>
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(LicencasCollector)