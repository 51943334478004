import React, {Fragment, useState, useEffect, useRef} from 'react'
import {Carousel, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import ImagenContadores from './Imagens/bannerContadores2.jpg'
import ImagemFinanceiro from './Imagens/bannerFinanceiro.jpg'
import ImagemTransito from './Imagens/bannerTransito.jpg'
import ImagemGestao from './Imagens/bannerContadores.jpg'

export default function Carrossel(props){
    return <div id='div-palarax'>
   
        <Carousel fade pause ={false}>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={ImagenContadores}
        alt="First slide"
      />
      <Carousel.Caption>
        <div>
        <h1>Buscando uma solução de <strong>Coleta de Contadores</strong> eficiente e competitiva ?</h1>
        <p>Conheça nosso módulo de coleta de contadores e vá além !</p>
        
        </div>
      </Carousel.Caption>
    </Carousel.Item>

    <Carousel.Item>
      <img
        className="d-block w-100"
        src={ImagemGestao}
        alt="Third slide"
      />
  
      <Carousel.Caption>
        <div>
        <h1>Gerencie seus Equipamentos, Contratos, Aberturas de Ordens de Serviços e Pedidos de Suprimentos !</h1>
        <p>Tudo isso em uma única plataforma de gestão.</p>
        
        </div>
      </Carousel.Caption>
    </Carousel.Item>


    <Carousel.Item>
      <img
        className="d-block w-100"
        src={ImagemFinanceiro}
        alt="Second slide"
      
      />
  
      <Carousel.Caption className='color-orange' >
        <div>
          <h1>Desperdício de impressão é um problema para sua empresa ?</h1>
          <p>Nós possuímos um módulo de Bilhetagem de Impressão para isso.</p>
          
        </div>
      </Carousel.Caption>
    </Carousel.Item>
    

    <Carousel.Item>
      <img
        className="d-block w-100"
        src={ImagemTransito}
        alt="Third slide"
      />
  
      <Carousel.Caption>
        <div>
        <h1>Temos uma solução de Rastreamento de Frota totalmente INTEGRADA !</h1>
        <p>Saiba em tempo real onde cada veículo se encontra e use informações de geolocalização para otimizar sua gestão.</p>
        
        </div>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  </div>
}