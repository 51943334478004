import React from 'react'
import BlockIcon from '@material-ui/icons/Block';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {Typography, CircularProgress} from '@material-ui/core'

export default function(props){

    const renderOpcao = () =>{
        if(props.loading){
            return <CircularProgress style ={{width :'20px', height:'20px'}} color='secondary'/>
        }else{
            if(props.ativo =='t'){
                return <div className='btn-ativo' onClick={()=>props.onclick()} ><CheckCircleOutlineIcon /></div>
            }else{
                return <div className='btn-inativo'  onClick={()=>props.onclick()}><RadioButtonUncheckedIcon style={{cursor:"pointer"}}/></div>
            }
        }
    }
    return renderOpcao();
}