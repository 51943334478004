import { getToken } from "./Autenticate";

var axios = require('axios');
var porta = 443;
var server = 'printerconnect.com.br'
var server2 = '127.0.0.1'
var protocolo ='https'
var md5 = require('md5');


const axiosInstance = axios.create({
  baseURL: protocolo + '://'+server+'/api/index.php/',
  timeout: 30000,
  headers: {    
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*', 
    
  }
  
});
 


  axiosInstance.interceptors.request.use(async config => {
  const token = md5('printerconnect#2021');//getToken();

  if (token) {
    config.headers.Authorization = 'Bearer '+token;
  }

  return config;

});
export default axiosInstance;
