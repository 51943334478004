import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import BallotIcon from '@material-ui/icons/Ballot';
import { TableHead, Table, TableCell, TableBody, TableRow } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LinearProgress from '@material-ui/core/LinearProgress';


import Swal from 'sweetalert2'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalDcas(props) {
  const [open, setOpen] = React.useState(false);
  const [registros, setRegistros] = React.useState([]);
  const [atualizando, setAtualizando] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
    getListaDca();
  };
  const handleClose = () => {
    setOpen(false);
  };


  const getListaDca = () =>{
    setAtualizando(true)
    props.Conexao.post('dcaController/getDca', {
      idusuario : props.idusuario , 
      idempresa : props.idempresa, 
      idcliente :props.idcliente 
    }).then(response =>{
      if(response.data.status =='OK'){
        setRegistros(response.data.payload)
      }else{
        setRegistros([])
      }
      setAtualizando(false)
    }).catch(error=>{
      setRegistros([])
      setAtualizando(false)
    });
  }

  return (
    <div>
      <BallotIcon color="primary" onClick={handleClickOpen} style ={{cursor:"pointer"}}/>
      <Dialog onClose={handleClose} fullWidth maxWidth='md' aria-labelledby="customized-dialog-title" open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Lista de DCA`s
        </DialogTitle>
        <DialogContent dividers>
          
          <Table className='table-small' style ={{fontSize:"10px"}}>
            <TableHead>
              <TableCell style ={{fontSize:"10px", width:"5%"}}>#</TableCell>
              <TableCell style ={{fontSize:"10px", width:"15%"}}>CHAVE</TableCell>
              <TableCell style ={{fontSize:"10px", width:"10%"}}>CRIAÇÃO</TableCell>
              <TableCell style ={{fontSize:"10px", width:"10%"}}>ATIVAÇÃO</TableCell>
              <TableCell style ={{fontSize:"10px", width:"10%"}}>ATUALIZAÇÃO</TableCell>
              <TableCell style ={{fontSize:"10px", width:"10%"}}>Delete</TableCell>
            </TableHead>

            <TableBody>
              
            {
            registros && registros.map(function(registro, i){
              return <TableRow>
              <TableCell style ={{fontSize:"10px", width:"5%"}}>{registro.iddca}</TableCell>
              <TableCell style ={{fontSize:"10px", width:"15%"}}>{registro.chave}</TableCell>
              <TableCell style ={{fontSize:"10px", width:"10%"}}>{registro.datacadastro}</TableCell>
              <TableCell style ={{fontSize:"10px", width:"10%"}}>{registro.dataativacao}</TableCell>
              <TableCell style ={{fontSize:"10px", width:"10%"}}>{registro.dataatualizacao}</TableCell>
              <TableCell style ={{fontSize:"10px", width:"10%"}}><Button variant='contained' color='secondary'  onClick={()=>{
                Swal.fire({
                  title : 'Printerconnect', 
                  text : 'Deseja remover este DCA agora ?', 
                  showConfirmButton : true, 
                  confirmButtonText : 'SIM', 
                  showCancelButton : true, 
                  cancelButtonText : 'NÃO', 
                  icon:'question'
                }).then(resposta=>{
                  if(resposta.isConfirmed){
                    props.Conexao.post('dcaController/removeDca', {
                      chave : props.chave, 
                      idusuario : props.idusuario , 
                      idempresa : props.idempresa, 
                      idcliente :props.idcliente, 
                      iddca : registro.iddca
      
                    }).then(response=>{
                      if(response.data.status =='OK'){
                        getListaDca();
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : 'Registro removido com sucesso !', 
                          showConfirmButton : false, 
                          confirmButtonText : 'OK', 
                          showCancelButton : false, 
                          cancelButtonText : 'NÃO', 
                          icon:'success', 
                          timer :1500
                        })
                      }else{
                       
                          Swal.fire({
                            title : 'Printerconnect', 
                            text : 'Não foi possível remove !', 
                            showConfirmButton : false, 
                            confirmButtonText : 'OK', 
                            showCancelButton : false, 
                            cancelButtonText : 'NÃO', 
                            icon:'warning', 
                            timer :1500
                          })
                      }
                    }).catch(error=>{
                      Swal.fire({
                        title : 'Printerconnect', 
                        text : 'Não foi possível remove !', 
                        showConfirmButton : false, 
                        confirmButtonText : 'OK', 
                        showCancelButton : false, 
                        cancelButtonText : 'NÃO', 
                        icon:'error', 
                        timer :1500
                      })
                    })
                  }
                })
              }}><DeleteForeverIcon/></Button></TableCell>
            </TableRow>
            })
          }
          {
            registros && registros.length == 0 && <TableRow>
            <TableCell style ={{fontSize:"10px", width:"5%"}} colSpan={5}>* Não há informações para serem exibidas.</TableCell>

          </TableRow>
          }
            </TableBody>

          </Table>
          {
            atualizando && <LinearProgress color="secondary" />
          }
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" variant='contained' onClick={()=>{
          Swal.fire({
            title : 'Printerconnect', 
            text : 'Deseja cadastrar um novo DCA agora ?', 
            showConfirmButton : true, 
            confirmButtonText : 'SIM', 
            showCancelButton : true, 
            cancelButtonText : 'NÃO', 
            icon:'question'
          }).then(resposta=>{
            if(resposta.isConfirmed){
              props.Conexao.post('dcaController/insertDca', {
                chave : props.chave, 
                idusuario : props.idusuario , 
                idempresa : props.idempresa, 
                idcliente :props.idcliente, 
                explode : 'T'

              }).then(response=>{
                if(response.data.status =='OK'){
                  getListaDca();
                  Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Registro cadastrado com sucesso !', 
                    showConfirmButton : false, 
                    confirmButtonText : 'OK', 
                    showCancelButton : false, 
                    cancelButtonText : 'NÃO', 
                    icon:'success', 
                    timer :1500
                  })
                }else{
                 
                    Swal.fire({
                      title : 'Printerconnect', 
                      text : 'Não foi possível cadastrar !', 
                      showConfirmButton : false, 
                      confirmButtonText : 'OK', 
                      showCancelButton : false, 
                      cancelButtonText : 'NÃO', 
                      icon:'warning', 
                      timer :1500
                    })
                }
              }).catch(error=>{
                Swal.fire({
                  title : 'Printerconnect', 
                  text : 'Não foi possível cadastrar !', 
                  showConfirmButton : false, 
                  confirmButtonText : 'OK', 
                  showCancelButton : false, 
                  cancelButtonText : 'NÃO', 
                  icon:'error', 
                  timer :1500
                })
              })
            }
          })
        }}>
            novo dca
          </Button>
          <Button variant='contained' autoFocus onClick={handleClose} color="secondary">
            fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
