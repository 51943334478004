import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import ModalNovoParametro from './ModalNovoParametro'
import ModalListaItemColeta from './ModalListaItemColeta'
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DisplayStatus from '../../components/DisplayStatus'



const CadastroDca = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [idLoading, setIdLoading] = useState(-1)
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [parametros, setParametros] = useState([])
    const [modelo, setModelo] = useState([])

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const refIdModelo = React.createRef();
    const refMarca = React.createRef();
    const refModelo = React.createRef();


    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getParametros()
    }, [])

    useEffect(()=>{
        if(openModal){
           
        }
    }, [openModal])


    const getParametros = () =>{
        Conexao.post('parametrosController/getParametros', {
            idusuario : props.match.params.idempresa, 
            idempresa : props.match.params.chaveEmpresa, 
            marca : refMarca.current ? refMarca.current.value  : '', 
            modelo : refModelo.current ? refModelo.current.value  : '', 
            idmodelo : refIdModelo.current ? refIdModelo.current.value  : '', 
        }).then( response =>{
            if(response.data.status == 'OK'){
                setParametros(response.data.payload)
            }else{
                setParametros([])
            }
        }).catch( error =>{
            setParametros([])
        });
    }

   

    return <Principal atualizar = {loading} getData={()=>getParametros()} full={true}>
     <Grid container spacing = {2}>
        <Grid item xs = {7}><Typography>Homologação de Templates de Coleta - {parametros.length} modelo(s) mapeado(s).</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
        <Grid item xs = {1}>
                <TextField
                    label ='ID'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}     
                    inputRef={refIdModelo}             
                   />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}     
                    inputRef={refMarca}             
                   />
            </Grid>
            <Grid item xs = {6}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    inputRef={refModelo}                  
                   />
            </Grid>

            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getParametros()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Templates</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table className='table table-bordered table-striped table-hover font-10 table-sm table-small' >
                <TableHead>
                    <TableCell align='left' style = {{width:"20%"}}>Nome Amigável do Modelo</TableCell>
                    <TableCell align='left' style = {{width:"15%"}}>Chave Pesquisa</TableCell>  
                    <TableCell align='center' style = {{width:"20%"}}>Marca</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Alertas</TableCell>    
                    <TableCell align='center' style = {{width:"5%"}}>Colorido</TableCell>                    
                    
                    <TableCell align='center' style = {{width:"8%"}}>Cadastro</TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>Parametros</TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>Eventos</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Status</TableCell>
                </TableHead>
                <TableBody>
                   {
                       parametros && parametros.map(function(parametro, i){
                           return <TableRow key ={'PAR'+i}>
                               <TableCell align='left' >{parametro.idmodelo +' - '+parametro.nomeamigavel}</TableCell>
                                <TableCell align='left' >{parametro.descricao}</TableCell>
                                <TableCell align='center' >{parametro.marca}</TableCell>
                               <TableCell align='center' >{parametro.erros > 0 ? <Badge badgeContent={parametro.erros} color='secondary'><ReportProblemIcon style = {{color:"red"}} color = 'secondary'/></Badge> : <></>}</TableCell>
                               <TableCell align='center' ><DisplayStatus loading={idLoading == parametro.idmodelo} ativo ={parametro.color} onclick={()=>{
                                  
                                   setIdLoading(parametro.idmodelo);
                                   Conexao.post('parametrosController/updateParametroColorModelo', {
                                        modelo : parametro.idmodelo, 
                                        atual : parametro.color
                                   }).then(response=>{
                                       if(response.data.status =='OK'){
                                           getParametros();
                                       }
                                      
                                       setIdLoading(-1)
                                   }).catch(error=>{
                                   
                                    setIdLoading(-1)
                                   })
                               }}/></TableCell>
                                                           
                                
                                <TableCell align='center' >{parametro.datacadastro}</TableCell>
                                <TableCell align='center' ><Badge badgeContent={parametro.parametros} color = 'secondary'><ExtensionTwoToneIcon style ={{cursor:"pointer"}} color = 'primary' onClick = {()=>{
                                    setModelo(parametro);
                                }}/> </Badge></TableCell>
                                <TableCell align='center' >{parametro.datacadastro}</TableCell>
                                <TableCell align='center' >Status</TableCell>
                           </TableRow>
                       })
                   }
                  
                </TableBody>
            </Table>
        </Grid>  
        </div>                   
      
        <div className='fabIncluir'>
            <ModalNovoParametro getData = {()=>getParametros()}  botaoAtivo={true}/>
        </div>
        <ModalListaItemColeta getData = {()=>getParametros()}  modelo={modelo} closeModal ={()=>{
                setModelo([])
            }}/>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroDca)