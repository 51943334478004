import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Container, Grid, TextField, InputAdornment, Tooltip, Checkbox, Table, TableCell, TableHead, TableBody, TableRow} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import IconeNovoLocal from '@material-ui/icons/AddLocation'
import LoopIcon from '@material-ui/icons/Loop';
import Swal from 'sweetalert2';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {Divider, Chip} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import Conexao from '../../providers/Conexao'
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
import ModalFull from '../../components/ModalFull'
import FormNovoLocalClientes from './FormNovoLocalClientes'
import IconeEditar from '@material-ui/icons/Brush'
import IconeExcluir from '@material-ui/icons/DeleteForever'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalEditarEquipamento(props) {
  const [showModal, setShowModal] =useState(false)
  const [open, setOpen] = React.useState(false);
  const [locais, setLocais]= React.useState([]);
  const [acao, setAcao] = React.useState('cadastro')
  const [localCliente, setLocalCliente] = React.useState([])
  


  const handleClickOpen = () => {
    setOpen(true);
    getLocais()
  };
  const handleClose = () => {
    setOpen(false);
  
  };

  useEffect(()=>{
      if(!showModal){
        setAcao('cadastro')
        setLocalCliente([])
      }
  },[showModal])


  const getLocais = () =>{

    Conexao.post('LocaisInstalacaoClienteController/getLocaisByCliente?idempresa='+props.edicaoClientes.idempresa+'&idcliente='+props.edicaoClientes.idcliente).then(function(response){
        if(response.data.status =='OK'){
            setLocais(response.data.payload)
        } else {
            setLocais([])
        }
    }).catch(error=>{
        setLocais([])
    });
  }


  return (
    <div>
      
      <Button variant='outlined' color='primary' onClick={handleClickOpen}><IconeNovoLocal /> Locais de Instalação</Button>
      


      <Dialog disableBackdropClick hideBackdrop onClose={handleClose} fullWidth aria-labelledby="customized-dialog-title" open={open } disableBackdropClick disableEscapeKeyDown maxWidth='lg'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Locais de Instalação Cadastrados : {props.titulo}
        </DialogTitle>
        <DialogContent dividers style={{height:"500px", overflow:'auto', overflowX:'hidden'}}>
            <Table className='table-sm table-hover table-striped table-bordered'>
                <TableHead>
                    <TableCell align='left' style ={{width:'20%'}}>Logradouro</TableCell>
                    <TableCell align='center' style ={{width:'5%'}}>Nº</TableCell>
                    <TableCell align='left' style ={{width:'10%'}}>Bairro</TableCell>
                    <TableCell align='center' style ={{width:'10%'}}>Cep</TableCell>
                    <TableCell align='left' style ={{width:'20%'}}>Cidade</TableCell>
                    <TableCell align='center' style ={{width:'5%'}}>Estado</TableCell>
                    <TableCell align='center' style ={{width:'5%'}}>Editar</TableCell>
                    <TableCell align='center' style ={{width:'5%'}}>Excluir</TableCell>
                </TableHead>
                <TableBody>
                    {
                        locais && locais.map(function(local, i){
                            return <TableRow key ={i}>
                            <TableCell align='left' style ={{width:'20%'}}>{local.logradouro}</TableCell>
                            <TableCell align='center' style ={{width:'5%'}}>{local.numero}</TableCell>
                            <TableCell align='left' style ={{width:'10%'}}>{local.bairro}</TableCell>
                            <TableCell align='center' style ={{width:'10%'}}>{local.cep}</TableCell>
                            <TableCell align='left' style ={{width:'20%'}}>{local.cidade}</TableCell>
                            <TableCell align='center' style ={{width:'5%'}}>{local.estado}</TableCell>
                            <TableCell align='center' style ={{width:'5%'}}><IconeEditar color='primary' style ={{cursor:'pointer'}} onClick={()=>{
                                setAcao('edicao')
                                setLocalCliente(local)
                                setShowModal(true)
                            }}/></TableCell>
                            <TableCell align='center' style ={{width:'5%'}}><IconeExcluir color='secondary' style ={{cursor:'pointer'}}/></TableCell>
                        </TableRow>
                        })
                    }
                </TableBody>
            </Table>
         
        </DialogContent>
        <DialogActions>
        <Button color='primary' onClick={()=>setShowModal(true)} variant='outlined'><IconeNovoLocal />Novo Local</Button>  
          <Button color='secondary' onClick={handleClose} variant='contained'>Fechar</Button>   
                 
        </DialogActions>
      </Dialog>

      <ModalFull
        titulo={acao=='cadastro' ? 'Cadastro de novo Local de Instalação : ' +props.edicaoClientes.idcliente+'-'+props.edicaoClientes.razaosocial : 'Edição de novo Local de Instalação : ' +props.edicaoClientes.idcliente+'-'+props.edicaoClientes.razaosocia}
        showModal ={showModal}
        idempresa = {props.edicaoClientes.idempresa}
        cliente = {props.edicaoClientes}
        closeModal = {()=>{
            setShowModal(false)
            
        }}
        >
        <FormNovoLocalClientes
            cliente = {props.edicaoClientes}
    
            closeModal = {()=>setShowModal(false)}
            updateLocalClientes = {()=>{getLocais()}}
            acao = {acao}
            localCliente={localCliente}
        />
        </ModalFull>

        
    </div>
  );
}
