import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import { Alert, AlertTitle } from '@material-ui/lab';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import BlockIcon from '@material-ui/icons/Block';
import UsbIcon from '@material-ui/icons/Usb';
import {Badge} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import PrintIcon from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';
import GraficoDca from './Componentes/GraficoPiza'//https://recharts.org/en-US/examples/TwoLevelPieChart
import DisplayToner from '../../components/DisplayToner'
import GraficoChamados from './Componentes/GaficoAberturaChamados'//https://recharts.org/en-US/examples/TwoLevelPieChart
import CardEquipamento from './Componentes/CardEquipamentos'
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 




let timer = null;
const DashboardColetas = (props)=>{
    const [loading, setLoading] = React.useState(false)
    const [dataGraficoDCAs, setDataGraficoDCAs] = React.useState([])
    const [dataGraficoEquipamentos, setDataGraficoEquipamentos] = React.useState([])
    const [dataGraficoColetas, setDataGraficoColetas]= React.useState([])
    const [idEmpresa, setIdEmpresa ] = React.useState(-1)
    const [dataHistoricoEquipamentos, setDataHistoricoEquipamentos] = React.useState([])
    const [dataHistoricoColetas, setDataHistoricoColetas] = React.useState([])
    const [dataLogAcessoCliente, setDataLogAcessoCliente] = React.useState([])
    const [dataProducaoPaginas, setDataProducaoPaginas] = React.useState([])
    const [classe, setClasse] = React.useState('card-dash-equipamento-container-main')

    const [dataGraficoClientes, setDataGraficoClientes] = React.useState({
        totalClientes : 0, 
        licencasCollectorAtivas : 0, 
        licencasCollectorInativas : 0
    })

    React.useEffect(()=>{
        if(idEmpresa > 0){
            getDataPainelProducao();
            getDataGraficoClientes();
            timer = setInterval(()=>{
                getDataGraficoClientes();
            }, 120000)
            return ()=>{
                clearInterval(timer);
            }
        }
       
    }, [idEmpresa]);

    React.useEffect(()=>{
     //   const empAtiva = JSON.parse(sessionStorage.getItem('empresaAtiva@printerConnect', null));
     
 
        setIdEmpresa(props.location.state.empresa.idempresa);
    },[])

   

    const getDataGraficoClientes = async() =>{
        
        Conexao.get('dashboardController/getDataGraficoClientes?idEmpresa='+idEmpresa).then(response=>{
            if(response.data.status =='OK'){
                setDataGraficoClientes(response.data.payload)
            }else{
                setDataGraficoClientes({
                    totalClientes : 0, 
                    licencasCollectorAtivas : 0, 
                    licencasCollectorInativas : 0
                });
            }
        }).catch(error=>{

        });
        getDataGraficoDCAs();
        getDataGraficoEquipamentos();
        getPainelColetas();
        getHistoricoEquipamentosLigados();
        getUltimasColetasRealizadas();
        getUltimosAcessoEmpresa();
        

    }


    const getDataGraficoDCAs = async () =>{
        Conexao.get('dashboardController/getDataGraficoDCAs?idEmpresa='+idEmpresa).then(response=>{
            if(response.data.status =='OK'){
                setDataGraficoDCAs(response.data.payload)
            }else{
                setDataGraficoDCAs([]);
            }
        }).catch(error=>{

        });
        

    }

    const getDataGraficoEquipamentos = async() =>{
        Conexao.get('dashboardController/getDataGraficoEquipamentos?idEmpresa='+idEmpresa).then(response=>{
            if(response.data.status =='OK'){
                setDataGraficoEquipamentos(response.data.payload)
            }else{
                setDataGraficoEquipamentos([]);
            }
        }).catch(error=>{

        });
        

    }

    const getPainelColetas = async() =>{
        Conexao.get('dashboardController/getPainelColetas?idEmpresa='+idEmpresa).then(response=>{
            if(response.data.status =='OK'){
                setDataGraficoColetas(response.data.payload)
            }else{
                setDataGraficoColetas([]);
            }
        }).catch(error=>{

        });
        

    }

    const getHistoricoEquipamentosLigados = async() =>{
        Conexao.get('dashboardController/getHistoricoEquipamentosLigados?idEmpresa='+idEmpresa).then(response=>{
            if(response.data.status =='OK'){
                setDataHistoricoEquipamentos(response.data.payload)
            }else{
                setDataHistoricoEquipamentos([]);
            }
        }).catch(error=>{

        });
    }


    const getUltimasColetasRealizadas = async() =>{
        Conexao.get('dashboardController/getUltimasColetasRealizadas?idEmpresa='+idEmpresa).then(response=>{
            if(response.data.status =='OK'){
                setDataHistoricoColetas(response.data.payload)
            }else{
                setDataHistoricoColetas([]);
            }
        }).catch(error=>{

        });
    }

    const getUltimosAcessoEmpresa = async() =>{
        Conexao.get('dashboardController/getUltimosAcessoEmpresa?idEmpresa='+idEmpresa).then(response=>{
            if(response.data.status =='OK'){
                setDataLogAcessoCliente(response.data.payload)
            }else{
                setDataLogAcessoCliente([]);
            }
        }).catch(error=>{

        });
    }


    const getDataPainelProducao = async() =>{
        Conexao.get('dashboardController/getDataPainelProducao?idEmpresa='+idEmpresa+'&intervalo=0').then(response=>{
            if(response.data.status =='OK'){
                setDataProducaoPaginas(response.data.payload)
            }else{
                setDataProducaoPaginas([]);
            }
        }).catch(error=>{

        });
    }

    


    

    const renderClasseAlert = (coleta) =>{
        if(coleta.nivelpreto >= 0 && coleta.nivelpreto <= 25){
            setClasse('card-dash-equipamento-container-main card-error')
        }else if(coleta.nivelpreto >= 26 && coleta.nivelpreto <= 50){
            setClasse('card-dash-equipamento-container-main card-warning')
        }
    }
    

    const renderStatus = (status, data, hora, dias) =>{
        if(dias > 0){
            return <div><span style = {{fontSize:"10px",}}>{data}</span><br/><Tooltip title ={'Sem comunicação há '+dias+' dia(s)'}><BlockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>SEM CONEXÃO</Typography></div>        
        }
        switch(status){
        case 1:return <div><span style = {{fontSize:"10px",}}>{data}</span><br/><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>{hora}</Typography></div>
                
            case 2:return <div><span style = {{fontSize:"10px",}}>{data}</span><br/><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>{hora}</Typography></div>
                
            case 3:return <div><span style = {{fontSize:"10px",}}>{data}</span><br/><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>{hora}</Typography></div>
                
            case 4:return <div><span style = {{fontSize:"10px",}}>{data}</span><br/><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>{hora}</Typography></div>
    
            case 5:return <div><span style = {{fontSize:"10px",}}>{data}</span><br/><ReportProblemIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>{hora}</Typography></div>
    
            default:return <div><span style = {{fontSize:"10px",}}>{data}</span><br/><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>{hora}</Typography></div>
        }
            
       }
    
return <Principal full = {'div-dashboard-full'} atualizar = {loading} >
     
        <div id='div-dashboard-container' >
            <div className = 'container-grafico'>
                <div className='container-status-dca' >
                        <Typography variant='caption' color='primary'>Licenças Collector</Typography>
                        <Divider/>
                        <div>
                            
                            <Typography variant='h1' className='display-xl ' color='primary'>{dataGraficoClientes.licencasCollectorAtivas + dataGraficoClientes.licencasCollectorInativas}</Typography>
                        </div>
                        <div style = {{display:"flex"}}>
                            <div style ={{width:"50%"}}>
                                <Typography variant='caption' color='primary' style = {{fontSize:"12px"}} >Ativas : </Typography>
                                <Typography variant='caption' color='primary' style = {{fontSize:"12px"}} >{dataGraficoClientes.licencasCollectorAtivas}</Typography>
                            </div>
                            <div style ={{width:"50%"}}>
                                <Typography variant='caption' color='primary' style = {{fontSize:"12px"}} >Inativas : </Typography>
                                <Typography variant='caption' color='primary' style = {{fontSize:"12px"}} >{dataGraficoClientes.licencasCollectorInativas}</Typography>
                            </div>                            
                        </div>
                        
                </div>
                <div className='container-status-dca' >
                        <Typography variant='caption' color='primary'>Coletores Cadastrados</Typography>
                        <Divider/>
                    <div className='container-status-dca-grafico'>                        
                        {dataGraficoDCAs.length > 0 ? <GraficoDca data = {dataGraficoDCAs.map(function(data){
                           return {
                            name : data.case, 
                            value : data.value
                           }
                        })}/> : <Alert severity='info' className='alert-default'>* Não existem dados !</Alert>}          
                    </div>
                    <div style ={{display:"flex", justifyContent:"spaceBetween"}}>
                        {
                                dataGraficoDCAs.map(function(data){
                                         return <div style ={{width:"50%", textAlign:"center"}}><Typography variant='caption' color='primary'>{data.case+' : '+data.value}</Typography ><br/></div >
                                    })
                            }
                    </div>
                </div>

                <div className='container-status-equipamentos' >
                        <Typography variant='caption' color='primary'>Equipamentos Monitorados</Typography>
                        <Divider/>
                    <div className='container-status-dca-grafico'>                          
                        {dataGraficoEquipamentos.length > 0  ? <GraficoDca  data = {dataGraficoEquipamentos.map(function(data){
                            return {
                                name : data.case, 
                                value : data.value
                            }
                        })}/> : <Alert severity='info' className='alert-default'>* Não existem dados !</Alert> }
                                         
                       
                        
                                             
                    </div>
                    <div style ={{display:"flex", justifyContent:"spaceBetween"}}>
                        {
                                dataGraficoEquipamentos.map(function(data){
                                         return <div style ={{width:"50%", textAlign:"center"}}><Typography variant='caption' color='primary'>{data.case+' : '+data.value}</Typography ><br/></div >
                                    })
                            }
                        </div>
                </div>
                
                <div className='container-status-chamado' >
                        <Typography variant='caption' color='primary'>Equipamentos Ligados (Este mês)</Typography>
                        <Divider/>
                    <div className='container-status-dca-grafico' style = {{paddingTop:"30px"}}>                        
                        {( dataHistoricoEquipamentos  ) ? <GraficoChamados data={dataHistoricoEquipamentos.grafico} total={dataHistoricoEquipamentos.total}/> : <Alert severity='info' className='alert-default'>* Não existem dados !</Alert> }                       
                    </div>
                    
                </div>
               
               
               
            </div>
            
           
        </div>      
        <br/>      
        <br/>
        <div style ={{display:"flex", paddingLeft:"21px"}}>
        
       <div style ={{width:"68%",  padding:"5px"}}>
            <Typography variant='subtitle1'>Equipamentos com nível de toner baixo </Typography>  
            <div className='container-chamados-dashboard'>
           
            {
                (dataGraficoColetas && dataGraficoColetas.length >0) ?  dataGraficoColetas.map(function(coleta, i){
                    return   <React.Fragment><CardEquipamento coleta={coleta}/></React.Fragment>
                }) :  <Alert severity='info' className='alert-default'>* Não existem dados !</Alert>
            }
            </div>
            <br/>
            <div style ={{width:"100%", display:"block",marginLeft:"5px", padding:"5px"}}>
                <Typography variant='subtitle1'>Log de acesso (Clientes)</Typography>
                {
                        (dataLogAcessoCliente && dataLogAcessoCliente.length >0) ? dataLogAcessoCliente.map(function(logAcesso, i){
                            return <Alert severity='info' style ={{marginTop:"10px"}} className='alert-default'><AlertTitle>{logAcesso.data+'  '+logAcesso.hora.substr(0,8)+ ' - '+logAcesso.nome}</AlertTitle><strong>{logAcesso.enderecoip}</strong>{' : '+logAcesso.razaosocial }</Alert>
                        }) : <Alert severity='info' className='alert-default font-small' >* Não existem dados !</Alert>
                    }
            </div>
       </div>
       <div style ={{width:"28%", display:"block", width:"30%",  marginLeft:"25px", padding:"10px"}}>
        <div style={{width:"100%", height:"130px", backgroundColor:"#fff", padding:"5px", textAlign:"center", borderRadius:"5px"}}>
        <Typography variant='subtitle1'>Produção Páginas Mensal (Mês atual)</Typography>
        <div style = {{display:"flex", justifyContent:'space-around'}}>
            <div style = {{width:"25%", textAlign:"center", padding:"5px"}}>
                <Typography variant='subtitle1' color='primary' style = {{fontSize:"12px"}}>Mono</Typography>
                <Typography variant='h4' color='primary'>{dataProducaoPaginas.producaoMono}</Typography>
            </div>
            <Typography variant='h4' color='primary'  style = {{marginTop:"24px"}}>+</Typography>
            <div style = {{width:"25%", textAlign:"center", padding:"5px"}}>
                <Typography variant='subtitle1' color='primary' style = {{fontSize:"12px"}}>Color</Typography>
                <Typography variant='h4' color='primary'>{dataProducaoPaginas.producaoColor}</Typography>
            </div>
            <Typography variant='h4' color='primary' style = {{marginTop:"24px"}}>=</Typography>
            <div style = {{width:"25%", textAlign:"center", padding:"5px"}}>
                <Typography variant='subtitle1' color='primary' style = {{fontSize:"12px"}}>Total</Typography>
                <Typography variant='h4' color='primary'>{dataProducaoPaginas.producaoColor + dataProducaoPaginas.producaoMono}</Typography>
            </div>
        </div>
       </div>
       <br/>
       <Typography variant='subtitle1'>Últimos equipamentos coletados</Typography>
       {
            (dataHistoricoColetas && dataHistoricoColetas.length > 0) ?  dataHistoricoColetas.map(function(coleta, i){
                return <div className='card-coleta-dash'>
                    <div style = {{width:"20%", textAlign:"center"}}>
                        {
                            renderStatus(coleta.statussistema, coleta.datacoleta, coleta.horacoleta.substr(0,8), coleta.dias)
                        }
                    </div>
                    <div  style = {{width:"80%"}}>
                        <div style={{width:"100%"}} className='texto-limitado' ><Typography variant='caption' color='primary' style = {{width:"100%"}}>{coleta.razaosocial}</Typography></div>
                        <div style={{width:"100%"}} className='texto-limitado' ><Typography variant='caption' color='primary' style = {{width:"100%"}}>{coleta.numeroserie+' - '+coleta.modelo}</Typography></div>
                        <div style={{width:"100%"}} className='texto-limitado' ><Typography variant='caption' color='primary' style = {{width:"100%"}}>{coleta.enderecoip}</Typography></div>
                    </div>
                   
                </div>
            }) : <Alert severity='info' className='alert-default'>* Não existem dados !</Alert>
        }
       </div>


     
        
        </div>
      
    </Principal>
}



export default withRouter(DashboardColetas)