import React, {Fragment, useState, useEffect, useRef} from 'react'
import './Index.css'
import NavPrincipal from './Componentes/Navbar'
import Carrossel from './Componentes/Carrossel'
import CardsTop from './Componentes/CardsTop'
import Sobrenos from './Componentes/Sobrenos'
import Faleconosco from './Componentes/Formcontato'
import Rodape from './Componentes/Rodape'

const Index =(props) =>{
    
    return <div id='div-main'>
        
        <NavPrincipal  {...props}/>
        <Carrossel/>
        <CardsTop />
        <Sobrenos />
        <Faleconosco />
        <Rodape />
        
    </div>
}


export default Index;