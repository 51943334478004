import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {Typography} from '@material-ui/core'
import ImagemInterface2 from './Imagens/Interface2.png'




export default function(props){
    return <React.Fragment><div id='sobre-container'>
        <Typography variant = 'h1'>Sobre o Printerconnect</Typography>
        <br/>
        <br/>
        <div id='sobre-container-text'>
        <p>Sua empresa ainda realiza manualmente a coleta dos contadores de impressão? Esta não é a forma mais eficiênte! Veja como a realidade de <strong>Provedores de Impressão</strong> pode ser transformada.<br/>
        Printerconnect é uma <strong>Solução Modular</strong>, focada em empresas de <strong>Outsourcing de Impressão</strong>, entregando agilidade, confiabilidade e segurança para tornar o dia a dia das empresas mais eficiente :</p>
        <br/>
    
    
        
        <Typography variant='h5'>Características</Typography>
        <br/>
        <br/>
        <ul>
        <li><b>Agilidade</b> para coletar contadores, detectar falhas e paradas dos equipamentos;
        </li><li><b>Fácil instalação</b>, o próprio prestador de serviços instala, configura e usa;
        </li><li><b>Redução de custos</b> operacionais com mão de obra, logística e telefone;
        </li><li><b>Simplicidade</b> no acompanhamento das impressões com gráfico de volumetria;
        </li><li><b>Segurança e sigilo</b> no armazenamento e comunicação dos dados;
        </li><li><b>Visualização</b> dos contadores de hardware e status de equipamentos;
        </li><li><b>Automatização </b> do processo de gerenciamento de outsourcing.
        </li><li><b>Otimização de estoque</b> de suprimentos e consumíveis;
        </li><li>Software, ajuda e suporte direto do fabricante e <b>em português</b>;
        </li><li><b>Integração</b> com sistemas gestão ERP;
        </li><li><b>Detecção automática</b> de equipamentos;
    </li></ul>
        <br/>
        <Typography variant='h5'>Segurança nas informações</Typography>
        <p>Todo o fluxo de informações do <strong>Printerconnect</strong> trafega de forma segura através de conexões SSL, desde a coleta de dados até sua visualização na plataforma.</p>
        </div>
    </div>
    <div id='div-container-pmc'>
        <div id ='div-imagem'>
            <img src ={ImagemInterface2} />
        </div>
       <div id='div-texto'>
           <Typography variant='h5'>Printerconnect Admin</Typography>
           <br/>
           <ul>
      		<strong>Funcionalidades:</strong>
      		<li>- Controle de Ordens de Serviço com cálculo de destâncias</li>
      		<li>- Coleta automática de contadores</li>
      		<li>- Pedido automático  de suprimentos</li>
      		<li>- Bilhetagem de impressão</li>
            <li>- Rastreamento de Frota através de GPS</li>
      	</ul>
       </div>
    </div>
    </React.Fragment>
}