import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import FormNovoCliente from './FormNovoCliente'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonAtivoInativo from '../../components/Comuns/ButtonAtivoInativo'
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import UsbIcon from '@material-ui/icons/Usb';
import PrintIcon from '@material-ui/icons/Print';
import CheckIcon from '@material-ui/icons/Check';
import DisplayStatus from '../../components/DisplayStatus'
import ModalGestaoAcessoCliente from './Componentes/ModalGestaoAcessoCliente'
import TextFieldEmail from '../../components/TextFieldEmail'
import IconeNovoLocal from '@material-ui/icons/AddLocation'
const CadastroClientes = (props)=>{
    const controller ='clientesController';
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [clientes, setClientes] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState('ativos')
    const [filtro, setFiltro] = useState([])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [paginas, setPaginas]= React.useState(1);
    const [anchorEl, setAnchorEl] = React.useState(null);





    const refStatusCadastro = useRef();

    const refCpnj = useRef();
    const refRazaoSocial = useRef();
    const refPlaca = useRef();
    const refCidade = useRef();
    const refMarca = useRef();
    const refModelo = useRef();
    const refSetor = useRef();
    const refErp = useRef();



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    useEffect(()=>{
        getClientes();
    }, [page])
    useEffect(()=>{
        getClientes();
    }, [rowsPerPage])


    useEffect(()=>{
       console.log(props)
    }, [])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const getClientes = () =>{
        
        setLoading(true)
        Conexao.post(controller+'/getClientes', {
            idUsuario : props.location.state.empresa.idrevenda, 
            idEmpresa : props.location.state.empresa.idempresa, 
            filtro : filtro, tipoFiltro : parametroFiltro, 
            status : refStatusCadastro.current ? refStatusCadastro.current.value : '', 
            cnpj : refCpnj.current ? refCpnj.current.value : '', 
            razaosocial : refRazaoSocial.current ? refRazaoSocial.current.value : '', 
            cidade : refCidade.current ? refCidade.current.value : '', 
            codigoClienteERP : refErp.current ? refErp.current.value : '', 
            limit: rowsPerPage,
            offSet :  page
        }).then(response =>{
            
            if(response.status == 200){
               
                if(response.data.status == 'OK'){
                   setClientes(response.data.payload.registros);
                   setPaginas(response.data.payload.totalRegistros)
                   
                }else{
                   setClientes([])
                }
            }else{
            setClientes([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
            setClientes([])
        })
    }

   /* const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.history.push('/cadastro_clientes/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa+'/page/'+newPage);
        getClientes(newPage)
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };*/


    return <Principal atualizar = {loading}>
   <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Gestão de Clientes</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={paginas}
                page={page}                
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {1}>
            <Grid item xs = {2}>
                <TextField
                    label='Status'
                    inputRef={refStatusCadastro}
                    size = 'small'
                    select
                    SelectProps  = {{native:true}}
                    InputLabelProps={{shrink : true}}
                    fullWidth
                    variant='outlined'>
                        <option >ATIVO</option>
                        <option >INATIVO</option>
                        <option >TODOS</option>
                    </TextField>
            </Grid>
            <Grid item xs = {1}>
                <TextField
                    label='Cod. ERP'
                    inputRef={refErp}
                    size = 'small'
                    InputLabelProps={{shrink : true}}
                    fullWidth
                    variant='outlined'/>
            </Grid>
           
            <Grid item xs = {2}>
                <TextField
                    label='CNPJ'
                    inputRef={refCpnj}
                    size = 'small'
                    InputLabelProps={{shrink : true}}
                    fullWidth
                    variant='outlined'/>
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Razão Social'
                    inputRef={refRazaoSocial}
                    variant ='outlined'
                    size = 'small'
                    fullWidth
                    InputLabelProps={{shrink : true}}
            
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Cidade'
                    inputRef={refCidade}
                    variant ='outlined'
                    size = 'small'
                    fullWidth
                    InputLabelProps={{shrink : true}}
            
                   />
            </Grid>
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>{getClientes()}}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{
                 
                }}><ClearIcon/> Limpar</Button>
            </Grid>
          
        </Grid>
       

        
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">gestão de clientes</Typography>
            </Breadcrumbs>
        </div>

    
   <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover '>
            <TableHead>
                <TableCell align='center' style = {{width:"5%"}}>ID</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>CNPJ</TableCell>
                <TableCell style = {{width:"45%"}}>Razão Social</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Cliente ERP</TableCell>                
                <TableCell align='center' style = {{width:"15%"}}>Cidade</TableCell>
                <TableCell align='center' style = {{width:"5%"}}>Estado</TableCell>
                <TableCell align='center' style = {{width:"5%"}}>Acesso</TableCell>
                <TableCell align='center' style = {{width:"5%"}}>Editar</TableCell>
                <TableCell align='center' style = {{width:"5%"}}>Status</TableCell>
            </TableHead>

            <TableBody>
                {
                    clientes && clientes.map(function(cliente, i){
                        return <TableRow key={'lGLC'+i} >
                               <TableCell align='center'><Typography variant='caption' color='primary'>{cliente.idcliente}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' color='primary'>{cliente.cpf_cnpj}</Typography></TableCell>
                                <TableCell>
                                    <Typography variant='subtitle2' color='primary'>{cliente.razaosocial}</Typography>                                                                     
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography variant='subtitle2' color='primary'>{cliente.idclienteinterno}</Typography>                                                                     
                                </TableCell>         
                                                      
                                <TableCell align='center'><Typography variant='caption' color='primary'>{cliente.cidade}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' color='primary'>{cliente.estado}</Typography></TableCell>                               
                                <TableCell align='center'>
                                    <ModalGestaoAcessoCliente 
                                        usuario={props.match.params.idempresa}
                                        empresa={props.match.params.chaveEmpresa}
                                        cliente={cliente}                                              
                                        razaosocial={cliente.razaosocial}/></TableCell>
                                <TableCell align='center' >                                                                  
                                <Tooltip title = 'Editar dados do cliente'><BuildTwoToneIcon color='primary' onClick={()=>{
                                                    if(cliente.ativo == 't'){
                                                        handleClose()
                                                        setEdicao(cliente)
                                                        setOpenModal(true)
                                                    }else{
                                                        Swal.fire({
                                                            title :'Gestão de Clientes', 
                                                            text : 'Não é permitida a edição de clientes inativos !!', 
                                                            showCanceldiv : true, 
                                                            showConfirmButton : false, 
                                                            cancelButtonText : 'Cancelar',
                                                            icon : 'warning'
                                                        })
                                                    }
                                                    
                                                }} style = {{cursor:"pointer"}}/></Tooltip><br/><Typography style ={{fontSize:"9px"}}>Editar</Typography>
         
                                </TableCell>
                                <TableCell align='center'>
                                <ButtonAtivoInativo 
                                        conexao={Conexao}
                                        label={cliente.ativo =='t' ? 'Cliente ativo, click para desativar' : 'Cliente inativo, click para ativar'} 
                                        controller={controller}
                                        action={cliente.ativo =='t' ? 'disableRegistro' : 'enableRegistro'}
                                        idempresa={props.match.params.chaveEmpresa} 
                                        identificador='idcliente'
                                        chave={cliente.idcliente}                                         
                                        updateData = {()=>getClientes()} />
                                </TableCell>
                            </TableRow> 
                    }) 
                }
                {clientes.length == 0 && <TableRow>
                    <TableCell colSpan={11}>
                    <Typography variant = 'caption' color='primary'>* Não existe(m) registro(s)</Typography>
                    </TableCell>
                    
                    
                    </TableRow>}
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    <ModalFull 
        showModal={openModal} 
        fixedSize={true}
        closeModal = {()=>{setOpenModal(false)}}
        titulo= {edicao && edicao.idcliente ? 'Alteração cliente : '+edicao.razaosocial+'('+edicao.idcliente+')' : 'Cadastrar Novo Cliente'} >
        <FormNovoCliente  
            idempresa={props.location.state.empresa.idrevenda}
            chaveEmpresa={props.location.state.empresa.idempresa}
            edicaoClientes = {edicao}
            updateClientes = {()=>getClientes()}
            closeModal = {()=>{setOpenModal(false)}}/>
    </ModalFull>
   
    
    <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpenModal(true)} >
          <AddIcon />
        </Fab>
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroClientes)