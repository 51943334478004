import React, {Fragment, useState, useEffect, useRef} from 'react'
import {Typography, Button} from '@material-ui/core'
import Logo from './logoPrinterConnect.png'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import LoginAcesso from '../../Login/LoginAcesso'
import AnchorLink from 'react-anchor-link-smooth-scroll'


export default function(props){
    
    return <div id='nav-container-main'>
        <div id='nav-container-logo'>
            <img src = './logoPrinterConnect.png' />
        </div>
        <div id='nav-container-menu'>
            <div id='nav-container-menu-itens'>
                <AnchorLink href='#card-container-main'><Typography variant='subtitle1'>Tecnologia</Typography></AnchorLink>
                <AnchorLink href='#sobre-container'><Typography variant='subtitle1'>Sobre Nós</Typography></AnchorLink>
                <AnchorLink href='#precos'><Typography variant='subtitle1'>Planos</Typography></AnchorLink>                        
                <AnchorLink href='#div-container-contato'><Typography variant='subtitle1'>Fale Conosco</Typography></AnchorLink>     
                  
           
                <a href ='https://www.printerconnect.com.br/downloads/printerconnec-app-setup.zip' attribute><Typography variant='subtitle1'>Download DCA</Typography></a>      
                <LoginAcesso  {...props} style = {{marginTop:"10px"}}/>
            </div>
           
        </div>
        <div id='nav-container-social'>
            <div id='nav-container-social-flex'>
            <div className='div-container-social'>
                    <FacebookIcon />
                </div>
                <div className='div-container-social'>
                    <LinkedInIcon />
                </div>
                <div className='div-container-social'>
                    <InstagramIcon />
                </div>
                <div className='div-container-social'>
                    <TwitterIcon />
                </div>
            </div>
                
        </div>
    </div>
}