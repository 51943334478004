import React, {Fragment, useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import Navbar from './Components/Navbar'
import LinearProgress from '@material-ui/core/LinearProgress';
import BarraPrincipal from './BarraPrincipal'
//55A14A9963S96224 4a 4f 45 4c 4d 41


const Principal = function(props){
    
  
    return <Fragment>
    <div id='navbar-fixed-top'>
    { props.atualizar && <LinearProgress color='secondary' className='div-progressbar'/>}
    <BarraPrincipal getData = {props.getData}/>
        <Navbar/>      
    </div>
    
    
    <div id={props.full ? 'form-container-area-full' : 'form-container-area'}>
        {
            props.children 
        }
    </div>

    </Fragment>
}

export default withRouter(Principal)