import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import DisplayStatus from '../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import GraficoTonerAmarelo from './GraficoLinhasAmarelo'
import GraficoTonerCiano from './GraficoLinhasCiano'
import GraficoTonerMagenta from './GraficoLinhasMagenta'
import GraficoTonerPreto from './GraficoLinhasPreto'
import {Alert, AlertTitle} from '@material-ui/lab/';
import ModalHabilitaMonitorIndividual from './ModalMonitoramentoIndividual'
import GraficoProducao from './Componentes/GraficoContadoresEquipamento'
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import LanguageIcon from '@material-ui/icons/Language';
import ListaAlertas from './ListaAlertas'


const LicencasCollector = (props)=>{
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    const [coleta, setColeta ]= React.useState([])
    const [dataGraficoDetalhesContadores, setDataGraficoDetalhesContadores]= React.useState([])
   
   

    React.useEffect(()=>{
     
        setColeta(props.state)
        getDetalhes(props.state.idcoleta)
    }, [])
        
    

    const getDetalhes = (idcoleta) =>{
        Conexao.get('coletasController/getDetalhes?idcoleta='+idcoleta ).then(response=>{
            if(response.data.status == 'OK'){
                setColeta(response.data.payload)
                getDataGraficoContadoresDetalhesEquipamento(response.data.payload.idempresa, response.data.payload.idcliente, 0, response.data.payload.numeroserie)
            }else{
                setColeta([])
            }
        }).catch(error =>{
            setColeta([])
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }


    const getDataGraficoContadoresDetalhesEquipamento = (idempresa, idcliente, intervalo, numeroserie) =>{
            Conexao.get('coletasController/getDataGraficoContadoresDetalhesEquipamento?idempresa='+idempresa+'&idcliente='+idcliente+'&intervalo='+intervalo+'&numeroserie='+numeroserie).then(response=>{
                if(response.data.status == 'OK'){
                    setDataGraficoDetalhesContadores(response.data.payload)
                }else{
                    setDataGraficoDetalhesContadores([])
                }
            }).catch(error =>{
                setColeta([])
                Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Sem conexão com servidor remoto !', 
                    showCancelButton : true,
                    cancelButtonText : 'Cancelar', 
                    showConfirmButton :false, 
                    icon : 'error'
                })
            });
    }

    const renderStatus = (status, dias) =>{
        if(dias > 0){
        return <div style ={{color:"#d50000"}}><BlockIcon /><Typography style = {{fontSize:"9px", marginTop:"0px"}}>SEM CONEXÃO HÁ {dias} dia(s)</Typography></div>
        }
        switch(status){
        case 1:return <div><HelpIcon style ={{color:"#d50000"}}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
                
            case 2:return <div><PowerSettingsNewIcon style ={{color:"#004d40"}}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ON LINE</Typography></div>
                
            case 3:return <div><SettingsPowerIcon style ={{color:"#ef6c00"}}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
                
            case 4:return <div><PrintIcon style ={{color:"#01579b"}}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>
    
            case 5:return <div><ReportProblemIcon style ={{color:"#d50000"}}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>
    
            default:return <div><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
        }
            
       }


       const renderTipoAlerta =(erro)=>{
           
            switch(erro.prtalertseveritylevel){
                case -1:
                    return <Alert severity="default" className='no-background' >
                                <AlertTitle>{erro.dataalerta+' '+erro.horaalerta.substr(0,8)}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>
                 case 1:
                    return <Alert severity="info" className='no-background' >
                                <AlertTitle>{erro.dataalerta+' '+erro.horaalerta.substr(0,8)}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>
                 case 2:
                    return <Alert severity="info" className='no-background' >
                                <AlertTitle>{erro.dataalerta+' '+erro.horaalerta.substr(0,8)}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>
                 case 3:
                    return <Alert severity="error" className='no-background' >
                                <AlertTitle>{erro.dataalerta+' '+erro.horaalerta.substr(0,8)}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>
                 case 4:
                    return <Alert severity="warning" className='no-background' >
                                <AlertTitle>{erro.dataalerta+' '+erro.horaalerta.substr(0,8)}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>

            }

        
       }

    return <div atualizar = {loading}>
      
        {coleta.idcoleta ?
        <div className='div-form-container' style = {{paddingTop:"3px", paddingLeft:"20px", paddingRight:"20px", minHeight:"50vh"}}>
        
        {
            coleta && coleta.ativo == 'f' && <Alert severity='error'>* A coleta para este equipamento esta desativada !!</Alert>
        }
    
        <Grid container  spacing={1}>
            <Grid item xs = {2}>
                <Typography variant='subtitle1' color='primary'>Número de Série</Typography>
                <Typography variant='subtitle2' color='primary'><strong>{coleta.numeroserie}</strong></Typography>
            </Grid>
            <Grid item xs = {3}>
                <Typography variant='subtitle1' color='primary'>Marca</Typography>
                <Typography variant='subtitle2' color='primary'>{coleta.marca}</Typography>
            </Grid>
            <Grid item xs = {3}>
                <Typography variant='subtitle1' color='primary'>Modelo</Typography>
                <Typography variant='subtitle2' color='primary'>{coleta.modelo}</Typography>
            </Grid>
            <Grid item xs = {4}>
                <Typography variant='subtitle1' color='primary'>Cliente</Typography>
                <Typography variant='subtitle2' color='primary'><strong>{ coleta.cliente ? coleta.cliente.razaosocial : coleta.razaosocial}</strong></Typography>
            </Grid>
        
        </Grid>
        <br/>
        <Grid container  spacing={1}>
            <Grid item xs = {2}>
                <Typography variant='subtitle1' color='primary'>Data 1º Coleta</Typography>
                <Typography variant='subtitle2' color='primary'>{coleta.datacadastro}</Typography>
            </Grid>
            <Grid item xs = {2}>
                <Typography variant='subtitle1' color='primary'>Hora 1º Coleta</Typography>
                <Typography variant='subtitle2' color='primary'>{coleta.horacadastro ? coleta.horacadastro.substr(0,8) : ''}</Typography>
            </Grid>
            
            <Grid item xs = {2}>
                <Typography variant='subtitle1' color='primary'>Data Última Coleta</Typography>
                <Typography variant='subtitle2' color='primary'>{coleta.datacoleta}</Typography>
            </Grid>
            <Grid item xs = {2}>
                <Typography variant='subtitle1' color='primary'>Hora Última Coleta</Typography>
                <Typography variant='subtitle2' color='primary'>{coleta.horacoleta ? coleta.horacoleta.substr(0,8) : ''}</Typography>
            </Grid>
            <Grid item xs = {2}>
                <Typography variant='subtitle1' color='primary'>Endereço IP</Typography>
                <Typography variant='subtitle2' color='primary'>{coleta.enderecoip}</Typography>
            </Grid>
            <Grid item xs = {2}>
                <Typography variant='subtitle1' color='primary'>Endereço MAC</Typography>
                <Typography variant='subtitle2' color='primary'>{coleta.macaddress}</Typography>
            </Grid>

        </Grid>
        <br/>
        <Grid container  spacing={1}>
            <Grid item xs = {4}>
                
                <Typography variant='subtitle1' color='primary'>Mensagem painel</Typography>
                <Typography variant='subtitle2' color='primary' style ={{height:"60%", backgroundColor:"#f8f9fa", padding:"5px"}}>{coleta.mensagempainel ? coleta.mensagempainel : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                
                <Typography variant='subtitle1' color='primary'>Status Sistema</Typography>
                <Typography variant='subtitle2' color='primary'>{renderStatus(coleta.statussistema, coleta.dias)}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='subtitle1' color='primary'>Status Copiadora</Typography>
                <Typography variant='subtitle2' color='primary'>{renderStatus(coleta.statuscopiadora, coleta.dias)}</Typography>
            </Grid>
            
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='subtitle1' color='primary'>Status Impressora</Typography>
                <Typography variant='subtitle2' color='primary'>{renderStatus(coleta.statusimpressora, coleta.dias)}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='subtitle1' color='primary'>Status Scanner</Typography>
                <Typography variant='subtitle2' color='primary'>{renderStatus(coleta.statusscanner, coleta.dias)}</Typography>
            </Grid>
            
            

        </Grid>
        <br/>
        {
             coleta.erros && <Typography variant='subtitle1'></Typography>
        }
            {
                coleta.erros && coleta.erros.map(function(erro, i){
                    return renderTipoAlerta(erro)
                })
            }
      
        <br/>
        <br/>
        <Grid container  spacing={1}>
            <Grid item xs = {12}>
            {coleta.cliente && coleta.cliente.monitorartoner =='t'  && <Alert severity="info" icon={<LanguageIcon className='iconAlert-g'/>}>Este cliente está habilitado para monitoramento <strong>global</strong> dos níveis de toner. Esta opção é uma ação com menor relevância ao monitoramento individual do equipamento, caso seja habilitado no equipamento ela terá precedência sobre a configuração global.</Alert>}
            </Grid>
            <Grid item xs = {3}>
               <Tooltip title = 'Habilitar monitoramento individual para este equipamento'>
                    <ModalHabilitaMonitorIndividual
                        getData = {()=>getDetalhes(coleta.idcoleta)}
                        inativo={coleta.ativo != 't' || (coleta.cliente && coleta.cliente.ativo != 't')}
                        coleta={coleta}>
                        {coleta.cliente && coleta.cliente.monitorartoner =='t'  && <Alert severity="info" >Este cliente está habilitado para monitoramento <strong>global</strong> dos níveis de toner.</Alert>}
                    </ModalHabilitaMonitorIndividual>
                </Tooltip>

            </Grid>

            <Grid item xs = {3}>
               {coleta.cliente && coleta.cliente.ativo == 't' ? <Tooltip title = 'Habilitar/Desabilitar coleta para este equipamento (a linceça não pode estar desabilitada)'>
                   {
                    coleta.ativo == 'f' ? <Button fullWidth className='btn-success' variant='outlined' color='primary' onClick={()=>{
                        Swal.fire({
                            title : 'Printerconnect', 
                            text :'Deseja habilitar o monitoramento deste equipamento agora ?', 
                            icon :'question', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Confirmar', 
                            showCancelButton : true,
                            cancelButtonText : 'Cancelar'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                                Conexao.post('coletasController/ativarColeta',{
                                    idEmpresa : props.match.params.chaveEmpresa, 
                                    idCliente : coleta.idcliente, 
                                    idColeta : coleta.idcoleta
                                }).then(response=>{
                                    if(response.data.status=='OK'){
                                        getDetalhes(coleta.idcoleta);
                                    }else{
                                        Swal.fire({
                                            title : 'Printerconnect', 
                                            text :'Não foi possível modificar registro', 
                                            icon :'warning', 
                                            showConfirmButton : false,                                             
                                            showCancelButton : false,                                            
                                            timer : 1500
                                        })
                                    }
                                }).catch(error=>{
                                    Swal.fire({
                                        title : 'Printerconnect', 
                                        text :'Não foi possível modificar registro', 
                                        icon :'error', 
                                        showConfirmButton : false,                                             
                                        showCancelButton : false,                                            
                                        timer : 1500
                                    })
                                });
                            }
                        });
                    }}>Ativar Coleta</Button> :<Button fullWidth className='btn-error' variant='outlined' color='secondary' onClick={()=>{
                        Swal.fire({
                            title : 'Printerconnect', 
                            text :'Deseja habilitar o monitoramento deste equipamento agora ?', 
                            icon :'question', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Confirmar', 
                            showCancelButton : true,
                            cancelButtonText : 'Cancelar'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                                Conexao.post('coletasController/desativarColeta',{
                                    idEmpresa : props.match.params.chaveEmpresa, 
                                    idCliente : coleta.idcliente, 
                                    idColeta : coleta.idcoleta
                                }).then(response=>{
                                    if(response.data.status=='OK'){
                                        getDetalhes(coleta.idcoleta);
                                    }else{
                                        Swal.fire({
                                            title : 'Printerconnect', 
                                            text :'Não foi possível modificar registro', 
                                            icon :'warning', 
                                            showConfirmButton : false,                                             
                                            showCancelButton : false,                                            
                                            timer : 1500
                                        })
                                    }
                                }).catch(error=>{
                                    Swal.fire({
                                        title : 'Printerconnect', 
                                        text :'Não foi possível modificar registro', 
                                        icon :'error', 
                                        showConfirmButton : false,                                             
                                        showCancelButton : false,                                            
                                        timer : 1500
                                    })
                                });
                            }
                        });
                    }}>Desativar Coleta</Button>
                   } 
                </Tooltip> : <Alert severity='warning'>* Esta <Link to={'/licencas_collect/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa+'/page/1'} >Licença de Coleta</Link> esta desabilitada ! Não é possível habilitar coleta do equipamento sem antes habilitar licença.</Alert>}

            </Grid>
        </Grid>
       
        
 
      

        <br/>
        <Divider/>
        <br/>
        <Typography variant='caption'>Informações sobre suprimentos</Typography>
        <br/>
       
        <br/>
        <Grid container  spacing={1}>

            <Grid item xs = {11}  >
                <Typography  variant='caption'>Evolução toner ciano</Typography>
               {coleta.numeroserie && <GraficoTonerCiano conexao ={Conexao} serial={coleta.numeroserie} />}
            </Grid>

            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <DisplayToner  percentual = {coleta.nivelciano ? coleta.nivelciano : 0} rotulo = 'ciano' color='#283593'  descricao={coleta.numeroserieciano} contador = {coleta.contadorciano}></DisplayToner>
               
            </Grid>
            {coleta.numeroserie && coleta.nivelciano == 0  && <Alert severity="error">Nível de toner Ciano está em zero !</Alert>}
            {coleta.numeroserie && coleta.nivelciano > 0 && coleta.nivelciano <= 25 && <Alert severity="warning">Equipamento sem Toner Ciano ! Verifique.</Alert>}
            <Typography variant='caption' color ='primary'>Estimativa para fim do suprimento :</Typography>
            
        </Grid>
        {
           coleta.cliente && coleta.cliente.monitorartoner == 't' && coleta.alertaciano == 'f' && <Alert severity="info" className='alert-default' icon={<LanguageIcon className='iconAlert-g'/>}>O Suprimento <strong>CIANO</strong> está sendo monitorado de forma <strong>GLOBAL</strong>, desta forma a configuração de níveis são as mesmas para todos os equipamentos : {coleta.cliente.nivelciano}%.</Alert>
           
        }
        {
            coleta && coleta.alertaciano == 't' && <Alert severity="info" className='alert-default' icon={<NetworkCheckIcon className='iconAlert-g'/>}>O Suprimento <strong>CIANO</strong> está sendo monitorado individualmente, alertas são gerados quando o Nível atingir : {coleta.nivelalertaciano}%.</Alert>
        }
        <br/>
        <Divider/>
       <br/><br/>
       
        <Grid container  spacing={1}>
      
        <Grid item xs = {11}  >
            <Typography variant='caption'>Evolução toner magenta</Typography>
            {coleta.numeroserie && <GraficoTonerMagenta conexao ={Conexao} serial={coleta.numeroserie}/>}
            </Grid>
            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <DisplayToner percentual = {coleta.nivelmagenta ? coleta.nivelmagenta : 0} rotulo = 'magenta' color='#ff1744' descricao={coleta.numeroseriemagenta} contador = {coleta.contadormagenta}></DisplayToner>
                
            </Grid>
            {coleta.numeroserie && coleta.nivelmagenta == 0 && <Alert severity="error">Equipamento sem Toner Magenta ! Verifique.</Alert>}
            {coleta.numeroserie && coleta.nivelmagenta > 0 && coleta.nivelmagenta <= 25 && <Alert severity="warning">Nível de toner Magenta está baixo !</Alert>}
            <Typography variant='caption' color ='primary'>Estimativa para fim do suprimento :</Typography>
           
        </Grid>
        {
           coleta.cliente && coleta.cliente.monitorartoner == 't' && coleta.alertamagenta == 'f' && <Alert severity="info" className='alert-default' icon={<LanguageIcon className='iconAlert-g'/>}>O Suprimento <strong>MAGENTA</strong> está sendo monitorado de forma <strong>GLOBAL</strong>, desta forma a configuração de níveis são as mesmas para todos os equipamentos : {coleta.cliente.nivelmagenta}%.</Alert>
           
        }
        {
            coleta && coleta.alertamagenta == 't' && <Alert severity="info" className='alert-default' icon={<NetworkCheckIcon className='iconAlert-g'/>}>O Suprimento <strong>MAGENTA</strong> está sendo monitorado individualmente, alertas são gerados quando o Nível atingir : {coleta.nivelalertamagenta}%.</Alert>
        }
        <br/>
        <Divider/>
        <br/><br/>
        <Grid container  spacing={1}>

            <Grid item xs = {11} >
            <Typography variant='caption'>Evolução toner amarelo</Typography>
            {coleta.numeroserie && <GraficoTonerAmarelo conexao ={Conexao} serial={coleta.numeroserie}/>}
            </Grid>
            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <DisplayToner percentual = {coleta.nivelamarelo ? coleta.nivelamarelo : 0} rotulo = 'amarelo' color='#ff8f00' descricao={coleta.numeroserieamarelo} contador = {coleta.contadoramarelo}></DisplayToner>
               
            </Grid>
            {coleta.numeroserie && coleta.nivelamarelo == 0 && <Alert severity="error">Equipamento sem Toner Amarelo ! Verifique.</Alert>}
            {coleta.numeroserie && coleta.nivelamarelo > 0 && coleta.nivelamarelo <= 25 && <Alert severity="warning">Nível de toner Amarelo está baixo !</Alert>}
            <Typography variant='caption' color ='primary'>Estimativa para fim do suprimento :</Typography>
        </Grid>
        {
           coleta.cliente && coleta.cliente.monitorartoner == 't' && coleta.alertaamarelo == 'f' && <Alert severity="info" className='alert-default' icon={<LanguageIcon className='iconAlert-g'/>}>O Suprimento <strong>AMARELO</strong> está sendo monitorado de forma <strong>GLOBAL</strong>, desta forma a configuração de níveis são as mesmas para todos os equipamentos : {coleta.cliente.nivelamarelo}%.</Alert>
           
        }
        {
            coleta && coleta.alertaamarelo == 't' && <Alert severity="info" className='alert-default' icon={<NetworkCheckIcon className='iconAlert-g'/>}>O Suprimento <strong>AMARELO</strong> está sendo monitorado individualmente, alertas são gerados quando o Nível atingir : {coleta.nivelalertaamarelo}%.</Alert>
        }
        <br/>
        <Divider/>
        <br/><br/>
        <Grid container  spacing={1}>
        
            <Grid item xs = {11}  >
            <Typography variant='caption'>Evolução toner preto</Typography>
            {coleta.numeroserie && <GraficoTonerPreto conexao ={Conexao} serial={coleta.numeroserie}/> }
            </Grid>
            
            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <DisplayToner percentual = {coleta.nivelpreto ? coleta.nivelpreto : 0} rotulo = 'preto' color='#000' descricao={coleta.numeroseriepreto} contador = {coleta.contadorpreto}></DisplayToner>
              
            </Grid>
            {coleta.numeroserie && coleta.nivelpreto == 0 && <Alert severity="error" >Equipamento sem Toner Preto ! Verifique.</Alert>}
            {coleta.numeroserie && coleta.nivelpreto > 0 && coleta.nivelpreto <= 25 && <Alert severity="warning" >Nível de toner Preto está baixo !</Alert>}
            <Typography variant='caption' color ='primary'>Estimativa para fim do suprimento :</Typography>
            
        </Grid>
        {
           coleta.cliente && coleta.cliente.monitorartoner == 't' && coleta.alertapreto == 'f' && <Alert severity="info" className='alert-default' icon={<LanguageIcon className='iconAlert-g'/>}>O Suprimento <strong>PRETO</strong> está sendo monitorado de forma <strong>GLOBAL</strong>, desta forma a configuração de níveis são as mesmas para todos os equipamentos : {coleta.cliente.nivelpreto}%.</Alert>
           
        }
        {
            coleta && coleta.alertapreto == 't' && <Alert severity="info" className='alert-default' icon={<NetworkCheckIcon className='iconAlert-g'/>}>O Suprimento <strong>PRETO</strong> está sendo monitorado individualmente, alertas são gerados quando o Nível atingir : {coleta.nivelalertapreto}%.</Alert>
        }
       <br/>
       <br/>
       <Divider/>

       
        
    
        <br/>
        <Typography variant='caption'>Informações sobre consumíveis</Typography>
        <br/>
        <br/>
        <Grid container  spacing={1}>
           

            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Unidade Laser</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorunitlaser ? coleta.contadorunitlaser : 'n/d'}</Typography>
            </Grid>

            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Unidade Fusora</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorunitfusao ? coleta.contadorunitfusao  : 'n/d'}</Typography>
            </Grid>

            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Unidade Cilindro</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorunitcilindro ? coleta.contadorunitcilindro  : 'n/d'}</Typography>
            </Grid>

            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Unidade Transferência</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorunittransferencia ?coleta.contadorunittransferencia  : 'n/d'}</Typography>
            </Grid>
        </Grid>
        
        <br/>
        <Divider/>
        <br/>
        <Typography variant='caption'>Informações sobre contadores Monocromáticos</Typography>
        <br/>
        <br/>
        <Grid container  spacing={1}>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>A4</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormonoa4 ?coleta.contadormonoa4  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>A3</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormonoa3 ?coleta.contadormonoa3  : 'n/d'}</Typography>
            </Grid>

            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Cópia</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormonocopia ?coleta.contadormonocopia  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Printer</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormonoprinter ?coleta.contadormonoprinter  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Fax</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormonofax ?coleta.contadormonofax  : 'n/d'}</Typography>
            </Grid>
            
            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Outros</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormonooutros ?coleta.contadormonooutros  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Total</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormonototal ?coleta.contadormonototal  : 'n/d'}</Typography>
            </Grid>

        </Grid>

        <br/>
        <Typography variant='caption'>Informações sobre contadores Coloridos</Typography>
        <br/>
        <br/>
        <Grid container  spacing={1}>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>A4</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcolora4 ?coleta.contadorcolora4  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>A3</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcolora3 ?coleta.contadorcolora3  : 'n/d'}</Typography>
            </Grid>

            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Cópia</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcolorcopia ?coleta.contadorcolorcopia  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Printer</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcolorprinter ?coleta.contadorcolorprinter  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Fax</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcolorfax ?coleta.contadorcolorfax  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Outros</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcoloroutros ?coleta.contadorcoloroutros  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Total</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcolortotal ?coleta.contadorcolortotal  : 'n/d'}</Typography>
            </Grid>

        </Grid>
        <br/>
        <Divider/>
        <Grid container  spacing={1}>
            <Grid item xs = {11} style = {{textAlign:"center"}}>
                
            </Grid>

            <Grid item xs = {1} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Total Geral</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcolortotal != null  ? coleta.contadortotalgeral : (coleta.contadormonototal + coleta.contadorcolortotal)}</Typography>
            </Grid>

        </Grid>
    
        <Typography variant='caption'>Informações sobre contadores de scanner</Typography>
        <br/>
        <br/>
        <Grid container  spacing={1}>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Contador 1</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormonoscanner ?coleta.contadormonoscanner  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Contador 2</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcolorscanner ?coleta.contadorcolorscanner  : 'n/d'}</Typography>
            </Grid>

        </Grid>

        <br/>
        <Typography variant='caption'>Informações sobre contadores de Metro</Typography>
        <br/>
        <br/>
        <Grid container  spacing={1}>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Contador de Centimetros</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorcentimetros ?coleta.contadorcentimetros  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Contador de Polegadas</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadorpolegadas ?coleta.contadorpolegadas  : 'n/d'}</Typography>
            </Grid>
            <Grid item xs = {2} style = {{textAlign:"center"}}>
                <Typography variant='caption' color='primary'>Contador de Metros</Typography><br/>
                <Typography variant='caption' color='primary'>{coleta.contadormetros ?coleta.contadormetros  : 'n/d'}</Typography>
            </Grid>

        </Grid>
        
        <br/>
        <br/>
        <div  style =  {{width:"100%", height:"250px"}}>
        <Typography variant='caption'color ='primary'>Evolução da produção de páginas</Typography>
        <GraficoProducao data={dataGraficoDetalhesContadores}/>
    </div>
    <br/>
        <br/> 
    </div> : <Alert severity='info'>* Não existem informações para serem exibidas</Alert>
   
}
     <ListaAlertas idempresa = {props.state.idempresa} idcliente ={props.state.idcliente} numeroserie = {coleta.numeroserie} showModal={props.showModal}/>
    </div>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(LicencasCollector)