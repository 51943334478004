import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import UsbTwoToneIcon from '@material-ui/icons/UsbTwoTone';
import Fab from '@material-ui/core/Fab';
import Conexao from '../providers/Conexao'
import {Grid, TextField, Divider, InputAdornment, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import AttachFileIcon from '@material-ui/icons/AttachFile';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function IconeLocalizarClienteModal(props) {
  const [open, setOpen] = React.useState(false);
  const [clientes, setClientes]= React.useState([]);

  const refFiltro = React.useRef();



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

 

  const getClientes = () =>{ 

    Conexao.post('clientesController/buscarCliente', {
      idusuario : props.idusuario, 
      idempresa : props.idempresa, 
      tipoFiltro : 'razaoSocial', 
      filtro : refFiltro.current ? refFiltro.current.value : '', 
      chaveCollector : props.chaveCollector ? 't' : ''

    }).then(response =>{
        if(response.data.status =='OK'){
            setClientes(response.data.payload);
        }else{
            setClientes([])
        }
    }).catch(error =>{
        setClientes([])
    });
  }
  return (
    <div>
     
     
      <SearchIcon onClick={handleClickOpen} style = {{cursor:'pointer'}}/>
      <Dialog 
        maxWidth='md'
        fullWidth
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        open={open} hideBackdrop 
        disableBackdropClick 
        disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.chaveCollector ? 'Localizar Chave de Instalação ' : 'Localizar Cliente'}
        </DialogTitle>
        <DialogContent dividers style ={{height : '500px'}}>
         <Grid container spacing = {2}>
           
           <Grid item xs = {10}>
             <TextField
                label = 'Filtro'
                variant= 'outlined'
                fullWidth
                InputLabelProps = {{shrink : true}}
                inputRef ={refFiltro} />
           </Grid>
           <Grid item xs = {2}>
             <Button color ='primary' onClick={()=>getClientes()}><SearchIcon /> Pesquisar</Button>
           </Grid>
         </Grid>
         <Table>
           <TableHead>
             <TableCell align='center' style ={{width:'10%'}}>Código</TableCell>
             <TableCell align='left' style ={{width:'50%'}}>Razão Social</TableCell>
             <TableCell align='center' style ={{width:'10%'}}>CNPJ</TableCell>
             <TableCell align='center' style ={{width:'20%'}}>Selecionar</TableCell>
           </TableHead>

           <TableBody>
             {
               clientes && clientes.map(function(cliente, i){
                 return <TableRow>
                 <TableCell align='center' >{cliente.idcliente}</TableCell>
                 <TableCell align='left' >{cliente.razaosocial}</TableCell>
                 <TableCell align='center' >{cliente.cpf_cnpj}</TableCell>
                 <TableCell align='center' >
                   <Button color='primary' onClick = {()=>{
                     setOpen(false)
                     props.selectCliente(cliente);
                   }}><AttachFileIcon/></Button>
                 </TableCell>
               </TableRow>
               })
             }
           </TableBody>
         </Table>
         
       
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
