import React, {Fragment} from 'react'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {Typography} from '@material-ui/core'

import NotificationsIcon from '@material-ui/icons/Notifications';

const DisplayLinearToner = (props) =>{


    return <div className='display-linear-toner' >
        <div className='display-toner-container'>
    
            <div className='display-toner-progress-nivel'>{props.value ? <div className={'display-toner-progress-'+props.rotulo} style = {{width:(props.value >= 0 && props.value <= 100) ? props.value : 0 +"%"}}></div> : <div></div>}</div>
            <div className='display-toner-span'><Typography variant='caption' className='font-9'>{props.value >= 0 && props.value <= 100 ? props.value+'%' : 'n/d'}</Typography></div>
        </div>
        <div className='display-toner-container-alert'>{(props.alerta && props.nivelAlerta > props.value)  && <React.Fragment><Tooltip title ={'Suprimento configurado para gerar alerta quando nível atingir '+props.nivelAlerta+'%'}><WarningIcon style = {{fontSize:"16px", color:"#ff8f00", cursor:"pointer"}}/></Tooltip></React.Fragment>}</div>
        
        
        
        </div>
}


export default DisplayLinearToner;
