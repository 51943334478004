import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import FormNovaEmpresa from './FormNovaEmpresa'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import DisplayStatus from '../../components/DisplayStatus'

const CadastroEmpresas = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [empresas, setEmpresas] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        
        getEmpresas();
    }, [])


    useEffect(()=>{
        
    },[])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const getEmpresas = () =>{
        
        setLoading(true)
        Conexao.post('empresasController/getEmpresas?idempresa='+props.location.state.empresa.idusuario, {
            filtro : filtro, tipoFiltro : parametroFiltro
        }).then(response =>{
            
            if(response.status == 200){
               
                if(response.data.status == 'OK'){
                    setEmpresas(response.data.payload);
                    console.log(response.data.payload)
                }else{
                    setEmpresas([])
                }
            }else{
                setEmpresas([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }

    const verificaEmpresaAtiva = (id, operacao) =>{
        
       return true;
    }


    return <Principal atualizar = {loading}>
    <Accordion elevation='0'>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style = {{display : 'flex', fontSize:'10px!important'}}>
      
        <Breadcrumbs aria-label="breadcrumb">            
            <Link color="inherit" to={"/home/revenda/"+props.location.state.empresa.idrevenda} >
                Home
            </Link>
        <Typography color="textPrimary">Cadasto de Empresas (Filiais)</Typography>
        </Breadcrumbs>
    </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing = {3}>
              <Grid item xs={3}>
                  <TextField
                    select
                    SelectProps = {{native : true}}
                    label='Filtar por'
                    variant='outlined'
                    value={parametroFiltro}
                    onChange = {(e)=>setParametroFiltro(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    fullWidth>
                        <option value='F'></option>
                        <option value ='razaoSocial'>Razão Social</option>
                        <option value ='nomeFantasia'>Fantasia</option>
                        <option value ='cnpj'>CNPJ</option>
                        <option value ='ativos'>Ativos</option>
                        <option value ='inativos'>Inativos</option>                        
                        <option value ='todos'>Todos</option>
                    </TextField>

              </Grid>
              <Grid item xs={4}>
                  <TextField
                    label='Filtro'
                    disabled= {parametroFiltro == 'ativos' || parametroFiltro == 'inativos' || parametroFiltro == 'todos'}
                    variant='outlined'
                    value={filtro}
                    onChange = {(e)=>setFiltro(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    fullWidth/>

              </Grid>
              <Grid item xs = {1}>
                  <Button className='fullButton' variant='outlined' color='primary' onClick={getEmpresas}>Pesquisar</Button>
              </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-sm'>
            <TableHead>
                <TableCell align='center'>#</TableCell>
                <TableCell align='center'>CNPJ</TableCell>
                <TableCell>Razão Social</TableCell>
                <TableCell align='center'>Cidade</TableCell>
                <TableCell align='center'>Estado</TableCell>
                <TableCell align='center'>Editar</TableCell>
                <TableCell align='center'>Padrão</TableCell>
                <TableCell align='center'>Status</TableCell>
            </TableHead>
            <TableBody>
                {
                    empresas && empresas.map(function(empresa, i){
                        return <TableRow id = {'EMP'+i}>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{(i + 1)}</Typography></TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{empresa.cpf_cnpj}</Typography></TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2' color='primary'>{empresa.razaosocial}</Typography>
                                        {empresa.logradouro &&<Typography variant='caption' color='primary'>{empresa.logradouro+', '+empresa.numero}</Typography>}
                                        {empresa.logradouro && <br/>}
                                        {empresa.cep && <Typography variant='caption' color='primary'>{empresa.cep+', '+empresa.bairro+(empresa.complemento ? '( '+empresa.complemento +' )': '')}</Typography>}
                                    </TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{empresa.cidade}</Typography></TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{empresa.estado}</Typography></TableCell>
                                    <TableCell align='center'>
                                        <Tooltip title ='Editar informações da Empresa'>
                                        <Button onClick = {()=>{
                                                setEdicao(empresa)
                                                setOpenModal(true)
                                                handleClose();
                                            }}><EditIcon color='primary'/></Button>
                                        </Tooltip>

                                      
                                                                                   
                                    </TableCell>
                                    <TableCell align='center'>
                                    <DisplayStatus ativo ={empresa.padrao} onclick={()=>{
                                             Swal.fire({
                                                icon : 'info',
                                                text :'Deseja tornar esta empresa padrão?',                  
                                                showCancelButton: true,
                                                confirmButtonText: 'Confirmar',
                                                cancelButtonText : 'Cancelar', 
                                                allowOutsideClick: () => !Swal.isLoading()
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    Conexao.get('empresasController/setarEmpresaAtiva?idusuario='+empresa.idusuario+'&idempresa='+empresa.idempresa+'&status=ativo').then(response =>{
                                                        if(response.data.status == 'OK'){
                                                            
                                                           
                                                            getEmpresas()
                                                           
                                                        }else{
                                                            Swal.fire({
                                                                text:'Operação não realizada', 
                                                                icon :'warning', 
                                                                timer : 1500, 
                                                                showCancelButton:false, 
                                                                showConfirmButton:false
                                                            })
                                                        }
                                                    }).catch(error =>{

                                                    })
                                                }
                                                })

                                        
                                        }}/>
                                    </TableCell>
                                    <TableCell align='center'>
                                    <DisplayStatus ativo ={empresa.ativo} onclick={()=>{
                                             Swal.fire({
                                                icon : 'info',
                                                text :'Deseja Realmente realizar operação  ?',                  
                                                showCancelButton: true,
                                                confirmButtonText: 'Confirmar',
                                                cancelButtonText : 'Cancelar', 
                                                allowOutsideClick: () => !Swal.isLoading()
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    Conexao.get('empresasController/disableEmpresa?idempresa='+empresa.idempresa+'&status='+empresa.ativo).then(response =>{
                                                        if(response.data.status == 'OK'){
                                                            
                                                           
                                                            const empr = JSON.parse(sessionStorage.getItem('empresaAtiva@printerConnect'));
                                                            
                                                            if(empr.idempresa == empresa.idempresa){
                                                                empr.ativa = empresa.ativo == 't' ? 'f' :'t'
                                                                sessionStorage.setItem('empresaAtiva@printerConnect', JSON.stringify(empr))
                                                                
                                                            //setEmpresas(empresas.filter(item => item.idempresa != empresa.idempresa ))
                                                            Swal.fire({
                                                                text:'Operação realizada com sucesso !', 
                                                                icon :'success', 
                                                                timer : 1500, 
                                                                showCancelButton:false, 
                                                                showConfirmButton:false
                                                            })
                                                            }
                                                            getEmpresas()
                                                           
                                                        }else{
                                                            Swal.fire({
                                                                text:'Operação não realizada', 
                                                                icon :'warning', 
                                                                timer : 1500, 
                                                                showCancelButton:false, 
                                                                showConfirmButton:false
                                                            })
                                                        }
                                                    }).catch(error =>{

                                                    })
                                                }
                                                })

                                        
                                        }}/>
                                    </TableCell>
                                    
                                </TableRow>
                    })
                }
            </TableBody>
        </Table>
    </Grid>
    <ModalFull fixedSize={true} titulo = {edicao.idempresa ? 'Editar dados da Empresa selecionada' :'Cadastro de Nova Empresa'} showModal = {openModal} closeModal= {()=>setOpenModal(false)}>
        <FormNovaEmpresa
            mode={edicao.idempresa ? 'edicao' : 'cadastro'} 
            edicaoEmpresa = {edicao}
            empresa = {props.location.state.empresa}  
            revenda = {props.location.state.empresa.idusuario}
            updateEmpresas= {()=>{
            getEmpresas();
           
            setOpenModal(false)}}
            />
    </ModalFull>

    <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpenModal(true)} >
          <AddIcon />
        </Fab>
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroEmpresas)