import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import VpnKeyTwoToneIcon from '@material-ui/icons/VpnKeyTwoTone';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import Conexao from '../../../providers/Conexao'
import IconeStatus from '../../../components/DisplayStatus'
import ModalNovoAcesso from './ModalNovoAcessoCliente'
import Swal from 'sweetalert2'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [usuarios, setUsuarios] = React.useState([])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(()=>{
    if(open){
        getData();
    }
  },[open]);


  const getData = () =>{
    Conexao.get('clientesController/getUsuariosCliente?idusuario='+props.cliente.idusuario+'&idempresa='+props.cliente.idempresa+'&idcliente='+props.cliente.idcliente).then(response=>{
        if(response.data.status =='OK'){
            setUsuarios(response.data.payload);
        }else{
            setUsuarios([]);
        }
    }).catch(error =>{
        setUsuarios([]);
    });
  }

  const updateStatusUsuarioCliente = (idacesso, parametro, status, index) =>{
    Conexao.get('clientesController/updateStatusUsuarioCliente?idusuario='+props.cliente.idusuario+'&idempresa='+props.cliente.idempresa
      +'&idcliente='+props.cliente.idcliente
      +'&idacesso='+idacesso
      +'&parametro='+parametro
      +'&status='+status).then(response=>{
        if(response.data.status =='OK'){
          Swal.fire({
            title :'Printerconnect', 
            text : 'Registro alterado com sucesso !', 
            showConfirmButton : false, 
            showCancelButton :false, 
            timer : 1500, 
            icon :'success'
          })
          getData();
        }else{
          Swal.fire({
            title :'Printerconnect', 
            text : 'Não foi possivel alterar!', 
            showConfirmButton : false, 
            showCancelButton :false, 
            timer : 1500, 
            icon :'warning'
          })
        }
    }).catch(error =>{
      Swal.fire({
        title :'Printerconnect', 
        text : 'Não foi possivel alterar!', 
        showConfirmButton : false, 
        showCancelButton :false, 
        timer : 1500, 
        icon :'error'
      })
    });
  }

  const gerarSenha = () => {
    var stringAleatoria = '';
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = 0; i < 3; i++) {
        stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    
    caracteres = '0123456789';
    for (var i = 0; i < 4; i++) {
        stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return stringAleatoria
  };
  
  return (
    <div>
      
      <React.Fragment><VpnKeyTwoToneIcon color="primary" onClick={handleClickOpen} style ={{cursor:"pointer"}}/><br/><span style ={{fontSize:"9px"}}>Acesso</span> </React.Fragment>
      <Dialog hideBackdrop maxWidth='md' fullWidth  onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Permissão de acesso para o cliente : {props.razaosocial}
        </DialogTitle>
        <DialogContent dividers style ={{height:"450px", overflow:"auto", overflowX:"hidden"}}>
            <Table style ={{fontSize:"10px!immportant"}}>
                <TableHead>
                    
                    <TableCell style ={{width:"30%"}}>Nome</TableCell>
                    <TableCell style ={{width:"40%"}}>email</TableCell>
                    <TableCell align='center' style ={{width:"5%"}}>Senha</TableCell>
                    <TableCell align='center' style ={{width:"5%"}}>Collector</TableCell>
                    <TableCell align='center' style ={{width:"5%"}}>Billing</TableCell>
                  
                    <TableCell align='center' style ={{width:"5%"}}>Suprimentos</TableCell>
                    <TableCell align='center' style ={{width:"5%"}}>Chamados</TableCell>
                    <TableCell align='center' style ={{width:"5%"}}>Ativo</TableCell>
                </TableHead>
            
            <TableBody>
                {
                    usuarios && usuarios.map(function(usuario, i){
                        return <TableRow key = {'user'+i}>
                      
                        <TableCell>{usuario.nome}</TableCell>
                        <TableCell>{usuario.email}</TableCell>
                    <TableCell><Button variant='outlined' color='primary' id={'BTN'+i} onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja gerar uma nova Senha', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  let senha = gerarSenha();
                                  Conexao.post('clientesController/atualizarSenha', {
                                    idusuario : usuario.idusuario,
                                    idempresa : usuario.idempresa,
                                    idcliente : usuario.idcliente,
                                    idacesso : usuario.idacesso, 
                                    senha : senha
                                  }).then(response=>{                                                                        
                                      
                                      if(response.data.status =='OK'){
                                        Swal.fire({
                                          title :'Printerconnect', 
                                          text : 'Senha gerada com sucesso : '+senha, 
                                          showConfirmButton : true, 
                                          showCancelButton :false,                                           
                                          icon :'success'
                                        })                                     
                                        
                                        
                                      }else{
                                        Swal.fire({
                                          title :'Printerconnect', 
                                          text : 'Não foi possivel alterar!', 
                                          showConfirmButton : false, 
                                          showCancelButton :false, 
                                          timer : 1500, 
                                          icon :'warning'
                                        })
                                      }
                                  }).catch(error =>{
                                    Swal.fire({
                                      title :'Printerconnect', 
                                      text : 'Não foi possivel alterar!', 
                                      showConfirmButton : false, 
                                      showCancelButton :false, 
                                      timer : 1500, 
                                      icon :'error'
                                    })
                                  });
                                }
                                
                              });
                              
                            }}>Gerar</Button></TableCell>
                        <TableCell align='center'>
                        {usuario.collector =='t' ? <CheckCircleOutlineIcon
                            style = {{color:"#00695c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar o acesso a coleta de contadores deste usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(!props.cliente.chavecoleta){
                                  Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Não existe uma Linceça de coleta para este cliente !'
                                  })
                                }else{
                                  if(response.isConfirmed){
                                    updateStatusUsuarioCliente(usuario.idacesso, 'collector', usuario.collector, i);
                                  }
                                }
                                
                              });
                              
                            }}/> :<RadioButtonUncheckedIcon
                            style = {{color:"#b71c1c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar o acesso a coleta de contadores para este usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(!props.cliente.chavecoleta){
                                  Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Não existe uma Linceça de coleta para este cliente !'
                                  })
                                }else{
                                  if(response.isConfirmed){
                                    updateStatusUsuarioCliente(usuario.idacesso, 'collector', usuario.collector, i);
                                  }

                                }
                               
                              });
                              
                            }}/>}
                        </TableCell>
                        <TableCell align='center'>
                        {usuario.billing =='t' ? <CheckCircleOutlineIcon
                            style = {{color:"#00695c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar o acesso a bilhetagem de impressão deste usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  updateStatusUsuarioCliente(usuario.idacesso, 'billing', usuario.billing, i);
                                }
                              });
                              
                            }}/> :<RadioButtonUncheckedIcon
                            style = {{color:"#b71c1c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar o acesso a bilhetagem de impressão para este usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  updateStatusUsuarioCliente(usuario.idacesso, 'billing', usuario.billing, i);
                                }
                              });
                              
                            }}/>}
                        </TableCell>
                        
                        <TableCell align='center'>
                        {usuario.suprimentos =='t' ? <CheckCircleOutlineIcon
                            style = {{color:"#00695c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar o acesso a chamados deste usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  updateStatusUsuarioCliente(usuario.idacesso, 'suprimentos', usuario.chamados, i);
                                }
                              });
                              
                            }}/> :<RadioButtonUncheckedIcon
                            style = {{color:"#b71c1c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar o acesso a suprimentos deste usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  updateStatusUsuarioCliente(usuario.idacesso, 'suprimentos', usuario.suprimentos, i);
                                }
                              });
                              
                            }}/>}
                        </TableCell>
                        <TableCell align='center'>
                        {usuario.chamados =='t' ? <CheckCircleOutlineIcon
                            style = {{color:"#00695c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar o acesso aos chamados deste usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  
                                  updateStatusUsuarioCliente(usuario.idacesso, 'chamados', usuario.chamados, i);
                                }
                              });
                              
                            }}/> :<RadioButtonUncheckedIcon
                            style = {{color:"#b71c1c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar este usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  updateStatusUsuarioCliente(usuario.idacesso, 'chamados', usuario.chamados, i);
                                }
                              });
                              
                            }}/>}
                        </TableCell>
                        <TableCell align='center'>
                        {usuario.ativo =='t' ? <CheckCircleOutlineIcon
                            style = {{color:"#00695c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar este usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  updateStatusUsuarioCliente(usuario.idacesso, 'ativo', usuario.ativo, i);
                                }
                              });
                              
                            }}/> :<RadioButtonUncheckedIcon
                            style = {{color:"#b71c1c", cursor:"pointer"}} 
                            onClick ={()=>{
                              Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja Ativar/Desativar este usuário ?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : true,
                                cancelButtonText : 'Cancelar'
                              }).then((response)=>{
                                if(response.isConfirmed){
                                  updateStatusUsuarioCliente(usuario.idacesso, 'ativo', usuario.ativo, i);
                                }
                              });
                              
                            }}/>}
                        </TableCell>
                    </TableRow>
                    })
                }
            </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
        <ModalNovoAcesso cliente={props.cliente} getData = {()=>getData()}/>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
