import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, Container,   Button,Divider, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldCNPJ from '../../components/TextFieldCNPJ'
import TextFieldTelefone from '../../components/TextFieldTelefone'
import TextFieldCelular from '../../components/TextFieldCelular'
import TextFieldCep from '../../components/TextFieldCep'
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';
import ModalBuscaCliente from './ModalBuscaCliente'



export default function FormNovoLocalClientes(props){
    const [cliente, setCliente] = useState([])
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [cep, setCep] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade,setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [telefoneComercial, setTelefoneComercial] = useState('')
    const [telefoneCelular, setTelefoneCelular]  = useState('')
    const [error, setError]  = useState(false)
    const [email, setEmail]  = useState('')
    

    useEffect(()=>{
        
        if(props.acao == 'edicao' && props.localCliente && props.localCliente.idcliente != ''){
 
            setCep(props.localCliente.cep)
            setEndereco(props.localCliente.logradouro)
            setNumero(props.localCliente.numero)
            setComplemento(props.localCliente.complemento)
            setBairro(props.localCliente.bairro)
            setCidade(props.localCliente.cidade)
            setEstado(props.localCliente.estado)
            setTelefoneComercial(props.localCliente.telefonecomercial ? props.localCliente.telefonecomercial : '')
            setTelefoneCelular(props.localCliente.telefonecelular ? props.localCliente.telefonecelular : '')
           
        }else{
            setCep()
            setEndereco()
            setNumero()
            setComplemento()
            setBairro()
            setCidade()
            setEstado()
            setTelefoneComercial()
            setTelefoneCelular()
        }
        
    }, [props.acao])

    useEffect(()=>{
        
        if(props.cliente && props.cliente.idcliente != ''){
            setCliente({
                idempresa : props.cliente.idempresa,
                idcliente:props.cliente.idcliente, 
                razaosocial : props.cliente.razaosocial, 
            })
           
        }
       
        
    }, [props.cliente])

    const updateLocaisCliente = () =>{
     
        setError(false)
        if( (!cep || cep =='') || (!endereco || endereco == '')  || (!numero || numero == '') || (!cidade || cidade == '') || (!estado || estado =='')){
            setError(true)
            Swal.fire({
                title :'Gestão Clientes', 
                text : 'Você precisa informar os campos obrigatórios !!', 
                icon : 'warning', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500, 
            })
            return
        }
        if(props.acao =='cadastro'){
            Conexao.post('locaisInstalacaoClienteController/insertLocalCliente', {
                idempresa : cliente.idempresa, 
                idcliente : cliente.idcliente, 
                endereco : endereco, 
                numero : numero, 
                cep :cep, 
                complemento : complemento ? complemento : '', 
                bairro : bairro, 
                cidade : cidade, 
                estado : estado, 
                telefonecomercial : telefoneComercial ? telefoneComercial : '', 
                telefonecelular : telefoneCelular ? telefoneCelular : '', 
                email : email
            }).then( response =>{
               
                if(response.data.status.trim() =='OK'){
                    Swal.fire({
                        title :'Gestão Clientes', 
                        text : 'Local de instalação gravado com sucesso !', 
                        icon : 'success', 
                        showCancelButton : false, 
                        showConfirmButton : false, 
                        timer : 1000, 
                        onClose : ()=>{
                            props.updateLocalClientes();
                            props.closeModal();
                        }
                    })
                }else{
                    Swal.fire({
                        title :'Gestão Clientes', 
                        text : 'Não foi possível gravar', 
                        icon : 'warning', 
                        showCancelButton : false, 
                        showConfirmButton : false, 
                        timer : 1000, 
                        onClose : ()=>{
                        // props.updateLocalClientes();
                            props.closeModal();
                        }
                    })
                }
            }).catch(error =>{
                alert(error)
                Swal.fire({
                    title :'Gestão Clientes', 
                    text : 'Não foi possível gravar', 
                    icon : 'danger', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1000, 
                    onClose : ()=>{
                        // props.updateLocalClientes();
                        props.closeModal();
                    }
                })
            })
        
        }else{

        
            Conexao.post('locaisInstalacaoClienteController/updateLocalClientes', {
                idempresa : props.localCliente.idempresa, 
                idcliente : props.localCliente.idcliente, 
                idlocal : props.localCliente.idlocal,
                endereco : endereco, 
                numero : numero, 
                cep :cep, 
                complemento : complemento, 
                bairro : bairro, 
                cidade : cidade, 
                estado : estado, 
                telefonecomercial : telefoneComercial, 
                telefonecelular : telefoneCelular

            }).then( response =>{
                if(response.data.status =='OK'){
                    Swal.fire({
                        title :'Gestão Clientes', 
                        text : 'Local de instalação atualizado com sucesso !', 
                        icon : 'success', 
                        showCancelButton : false, 
                        showConfirmButton : false, 
                        timer : 1000, 
                        onClose : ()=>{
                            props.updateLocalClientes();
                            props.closeModal();
                        }
                    })
                }else{
                    Swal.fire({
                        title :'Gestão Clientes', 
                        text : 'Não foi possível atualizar', 
                        icon : 'warning', 
                        showCancelButton : false, 
                        showConfirmButton : false, 
                        timer : 1000, 
                        onClose : ()=>{
                        // props.updateLocalClientes();
                            props.closeModal();
                        }
                    })
                }
            }).catch(error =>{
                Swal.fire({
                    title :'Gestão Clientes', 
                    text : 'Não foi possível atualizar', 
                    icon : 'danger', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1000, 
                    onClose : ()=>{
                        // props.updateLocalClientes();
                        props.closeModal();
                    }
                })
            })
        }


    }



    return <Container maxWidth="lg" >
        <Divider/>
            <br/>
        <Grid container spacing={2}>
            
            <Grid item xs = {2} alignItems='left'>
            <TextField
                    variant='outlined'
                    label='ID'
                    value={props.localCliente.idcliente ? props.localCliente.idcliente : cliente.idcliente}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {!props.localCliente.idcliente && !cliente.idcliente && <ModalBuscaCliente 
                                updateCliente = {(cliente)=>{
                                    setCliente(cliente)
                                   
                                }}
                            /> }
                        </InputAdornment>,
                    }}
                    fullWidth/>
               
            </Grid>
            <Grid item xs = {10} alignItems='left'>
            <TextField
                    variant='outlined'
                    label='Razão Social'
                    value={props.localCliente.razaosocial ? props.localCliente.razaosocial : cliente.razaosocial}
                    fullWidth/>
               
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2}>
            
            <Grid item xs = {2} alignItems='left'>
                <TextFieldCep
                     error ={ error && cep==''}
                     helperText={ error && cep=='' ?'Cep não informado':''}
                     onChange={(e)=>setCep(e.target.value)}
                     variant='outlined'
                     required
                     label='Cep'
                     value={cep}
                     fullWidth
                     retornoCep = {(data)=>{
                        if(!data.erro && data){
                            setEndereco(data.logradouro)
                            setComplemento(data.complemento)
                            setBairro(data.bairro)
                            setCidade(data.localidade)
                            setEstado(data.uf)
                        }else{
                            setEndereco('')
                            setComplemento('')
                            setBairro('')
                            setCidade('')
                            setEstado('')
                         Swal.fire({
                             text:'Não existe endereço vinculado ao CEP informado !', 
                             icon :'warning', 
                         })
                        }

                    }}
                     />
               
            </Grid>
       
            <Grid item xs = {5} alignItems='left'>
                <TextField
                    variant='outlined'
                    error ={ error && endereco==''}
                    helperText={ error && endereco=='' ?'Endeceço não informado':''}
                    onChange={(e)=>setEndereco(e.target.value)}
                    required
                    label='Endereço'
                    value={endereco}
                    InputLabelProps = {{shrink : true}}
                    fullWidth/>
            </Grid>
       
            <Grid item xs = {1} alignItems='left'>
                <TextField
                    variant='outlined'
                    error ={ error && numero==''}
                    onChange={(e)=>setNumero(e.target.value)}
                    required
                    label='Número'
                    InputLabelProps = {{shrink : true}}
                    value={numero}
                    fullWidth/>
            </Grid>
        
            <Grid item xs = {4} alignItems='left'>
                <TextField
                    variant='outlined'
                    label='Complemento'
                    InputLabelProps = {{shrink : true}}
                    value={complemento}
                    onChange={(e)=>setComplemento(e.target.value)}
                    fullWidth/>
            </Grid>
       
            <Grid item xs = {4} alignItems='left'>
                <TextField
                    variant='outlined'
                    error ={ error && bairro==''}
                    onChange={(e)=>setBairro(e.target.value)}
                    helperText={ error && bairro=='' ?'Bairro não informado':''}
                    required
                    label='Bairro'
                    InputLabelProps = {{shrink : true}}
                    value={bairro}
                    fullWidth/>
            </Grid>
         
            <Grid item xs = {7} alignItems='left'>
                <TextField
                    variant='outlined'
                    error ={ error && cidade==''}
                    helperText={ error && cidade=='' ?'Cidade não informada':''}
                    InputLabelProps = {{shrink : true}}
                    value={cidade}
                    label='Cidade'
                    onChange={(e)=>setCidade(e.target.value)}
                    required
                    fullWidth/>
            </Grid>
       
            <Grid item xs = {1} alignItems='left'>
                <TextField
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    error ={ error && estado==''}
                    helperText={ error && estado=='' ?'Estado não informado':''}
                    required
                    value={estado}
                    label='Estado'
                    inputProps={{
                        'size' :'2', 
                        'maxlength' :'2'
                    }}
                    onChange={(e)=>setEstado(e.target.value)}
                    fullWidth/>
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2}>
        
            <Grid item xs = {2} alignItems='left'>
                <TextFieldTelefone
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    labe='Telefone Comercial'
                    value={telefoneComercial}
                    onChange={(e)=>setTelefoneComercial(e.target.value)}
                    fullWidth/>
            </Grid>
       
            <Grid item xs = {2} alignItems='left'>
                <TextFieldCelular
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    label='Telefone Celular'
                    value={telefoneCelular}
                    onChange={(e)=>setTelefoneCelular(e.target.value)}
                    fullWidth/>
            </Grid>
       
            <Grid item xs = {8} alignItems='left'>
                <TextField
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    label='Email'
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    fullWidth/>
            </Grid>
        </Grid>
        <br/>
        <Divider/>
        <br/>
        <Grid container spacing={2}>
        <Grid item xs = {8} align='right' color='primary'></Grid>
            <Grid item xs = {2} align='right' color='primary'>
            <Button fullWidth size='large' color='secondary' variant='contained' onClick={()=>
                Swal.fire({
                    title :'Gestão Clientes', 
                    text :'Cancelar as operações ?', 
                    icon : 'info', 
                    showConfirmButton : true, 
                    confirmButtonText : 'Confirmar', 
                    showCancelButton : true, 
                    cancelButtonText :'Cancelar', 
                }).then((result)=>{
                    if(result.isConfirmed){
                        props.closeModal()
                    }
                })
            }>CANCELAR</Button>
            </Grid>
            <Grid item xs = {2} alignItems='left'>
                <Button fullWidth size='large' color='primary' variant='contained' onClick = {()=>{
                    Swal.fire({
                    title :'Gestão Clientes', 
                    text :'Deseja gravar as alterações ?', 
                    icon : 'info', 
                    showConfirmButton : true, 
                    confirmButtonText : 'Confirmar', 
                    showCancelButton : true, 
                    cancelButtonText :'Cancelar', 
                }).then((result)=>{
                    if(result.isConfirmed){
                        updateLocaisCliente()
                    }
                })
                }}>GRAVAR</Button>
            </Grid>
        </Grid>
    </Container>;
}