import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'
import { Alert, AlertTitle } from '@material-ui/lab';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal_original'
import BlockIcon from '@material-ui/icons/Block';
import UsbIcon from '@material-ui/icons/Usb';
import {Badge} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import PrintIcon from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';
import GraficoTop5Imp from './Componentes/GraficoPiza'//https://recharts.org/en-US/examples/TwoLevelPieChart
import GraficoTop5User from './Componentes/GraficoPiza'//https://recharts.org/en-US/examples/TwoLevelPieChart
import DisplayToner from '../../../components/DisplayToner'
import GraficoChamados from './Componentes/GaficoAberturaChamados'//https://recharts.org/en-US/examples/TwoLevelPieChart
import CardEquipamento from './Componentes/CardEquipamentos'
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 

import ImgAgua from '../Principal/Components/img/iconeAgua2.png';
import ImgEnergia from '../Principal/Components/img/iconeEnergia2.png';
import ImgCo2 from '../Principal/Components/img/iconeGas.png';
import ImgArvore from '../Principal/Components/img/iconeArvore.png';


let timer = null;
const DashboardColetasCliente = (props) => {
    const [loading, setLoading] = React.useState(false)
    const [dataGraficoDCAs, setDataGraficoDCAs] = React.useState([])
    const [dataGraficoEquipamentos, setDataGraficoEquipamentos] = React.useState([])
    const [dataGraficoColetas, setDataGraficoColetas] = React.useState([])
    const [idEmpresa, setIdEmpresa] = React.useState(-1)
    const [dataHistoricoEquipamentos, setDataHistoricoEquipamentos] = React.useState([])
    const [dataHistoricoColetas, setDataHistoricoColetas] = React.useState([])
    const [dataProducaoPaginas, setDataProducaoPaginas] = React.useState([])
    const [dataImpactoAmbiental, setDataImpactoAbiental] = React.useState([])
    const [classe, setClasse] = React.useState('card-dash-equipamento-container-main')

    const [dataTop5Impressoras, setDataTop5Impressoras] = React.useState([])
    const [dataTop5Usuarios, setDataTop5Usuarios] = React.useState([])

    const [dataGraficoClientes, setDataGraficoClientes] = React.useState({
        totalClientes: 0,
        licencasCollectorAtivas: 0,
        licencasCollectorInativas: 0
    })


    const [logsImpressao, setLogsImpressao] = React.useState([])


    React.useEffect(() => {
        if (idEmpresa > 0) {
            getDataPainelProducao()
            getDataGraficoClientes();
            getDataImpactoAmbiental();
            getDataTop5Impressoras();
            getDataTop5Usuarios();

            timer = setInterval(() => {
                getDataTop5Impressoras();
                getDataTop5Usuarios();
                getDataImpactoAmbiental();
                getDataGraficoClientes();
            }, 30000)
            return () => {
                clearInterval(timer);
            }
        }
       
    }, [idEmpresa]);

    React.useEffect(()=>{
       
        setIdEmpresa(props.match.params.chaveEmpresa);
    },[])

   

    const getDataGraficoClientes = async () => {

        await Conexao.get('dashboardController/getDataGraficoClientes?idEmpresa=' + idEmpresa + '&idcliente=' + props.match.params.idcliente).then(response => {
            if (response.data.status == 'OK') {
                setDataGraficoClientes(response.data.payload)
            } else {
                setDataGraficoClientes({
                    totalClientes: 0,
                    licencasCollectorAtivas: 0,
                    licencasCollectorInativas: 0
                });
            }
        }).catch(error => {

        });
        getDataGraficoDCAs();
        getDataGraficoEquipamentos();
        getPainelColetas();
        getHistoricoEquipamentosLigados();
        getUltimasColetasRealizadas();
        getLastJobsFilaPainel();

    }


    const getDataGraficoDCAs = async () =>{
       await  Conexao.get('dashboardController/getDataGraficoDCAs?idEmpresa='+idEmpresa+'&idcliente='+props.match.params.idcliente).then(response=>{
            if(response.data.status =='OK'){
                setDataGraficoDCAs(response.data.payload)
            }else{
                setDataGraficoDCAs([]);
            }
        }).catch(error=>{

        });
}


const getDataTop5Impressoras  = async () =>{
    await Conexao.get('relatoriosBillingController/impressoesByFila?idempresa='+idEmpresa+'&idcliente='+props.match.params.idcliente+'&hostname=&nomefila=&username=&datainicial=&datafinal=&detalhes=F&topfive=T&dashboard=T').then(response=>{
        if(response.data.status =='OK'){
            //setDataTop5Impressoras(response.data.payload)
        }else{
            setDataTop5Impressoras([])
        }
    })
}



    const getDataTop5Usuarios = async () => {
        await Conexao.get('relatoriosBillingController/impressoesByUserName?idempresa=' + idEmpresa + '&idcliente=' + props.match.params.idcliente + '&hostname=&nomefila=&username=&datainicial=&datafinal=&detalhes=F&topfive=T&dashboard=T').then(response => {
            if (response.data.status == 'OK') {
                setDataTop5Usuarios(response.data.payload)
            } else {
                setDataTop5Usuarios([])
            }
        })
    }



    const getDataImpactoAmbiental  = async () =>{
        await Conexao.get('dashboardController/impactoAmbiental?idempresa='+idEmpresa+'&idcliente='+props.match.params.idcliente).then(response=>{
            if(response.data.status =='OK'){
                setDataImpactoAbiental(response.data.payload)
            }else{
                setDataImpactoAbiental([])
            }
        })
    }

    const getDataGraficoEquipamentos = async () => {
        await Conexao.get('dashboardController/getDataGraficoEquipamentos?idEmpresa=' + idEmpresa + '&idcliente=' + props.match.params.idcliente).then(response => {
            if (response.data.status == 'OK') {
                setDataGraficoEquipamentos(response.data.payload)
            } else {
                setDataGraficoEquipamentos([]);
            }
        }).catch(error => {

        });

    }

    const getPainelColetas = async () => {
        await Conexao.get('dashboardController/getPainelColetas?idEmpresa=' + idEmpresa + '&idcliente=' + props.match.params.idcliente).then(response => {
            if (response.data.status == 'OK') {
                setDataGraficoColetas(response.data.payload)
            } else {
                setDataGraficoColetas([]);
            }
        }).catch(error => {

        });

    }

    const getHistoricoEquipamentosLigados = async () => {
        await Conexao.get('dashboardController/getHistoricoEquipamentosLigados?idEmpresa=' + idEmpresa + '&idcliente=' + props.match.params.idcliente).then(response => {
            if (response.data.status == 'OK') {
                setDataHistoricoEquipamentos(response.data.payload)
            } else {
                setDataHistoricoEquipamentos([]);
            }
        }).catch(error => {

        });
    }


    const getUltimasColetasRealizadas = async () =>{
       await Conexao.get('dashboardController/getUltimasColetasRealizadas?idEmpresa='+idEmpresa+'&idcliente='+props.match.params.idcliente).then(response=>{
            if(response.data.status =='OK'){
                setDataHistoricoColetas(response.data.payload)
            }else{
                setDataHistoricoColetas([]);
            }
        }).catch(error=>{

        });
    }

    const getDataPainelProducao = async () =>{
       await Conexao.get('dashboardController/getDataPainelProducao?idEmpresa='+idEmpresa+'&intervalo=0&idcliente='+props.match.params.idcliente).then(response=>{
            if(response.data.status =='OK'){
                setDataProducaoPaginas(response.data.payload)
            }else{
                setDataProducaoPaginas([]);
            }
        }).catch(error=>{

        });
    }

    const getLastJobsFilaPainel = async () =>{
       await Conexao.get('bilhetagemController/getLastJobsFilaPainel?idEmpresa='+idEmpresa+'&intervalo=0&idCliente='+props.match.params.idcliente).then(response=>{
            if(response.data.status =='OK'){
                setLogsImpressao(response.data.payload)
            }else{
                setLogsImpressao([]);
            }
        }).catch(error=>{

        });
    }
    const renderClasseAlert = (coleta) =>{
        if(coleta.nivelpreto >= 0 && coleta.nivelpreto <= 25){
            setClasse('card-dash-equipamento-container-main card-error')
        }else if(coleta.nivelpreto >= 26 && coleta.nivelpreto <= 50){
            setClasse('card-dash-equipamento-container-main card-warning')
        }
    }

    const renderStatus = (status, data, hora, dias) => {
        if (dias > 0) {
            return <div><span style={{ fontSize: "10px", }}>{data}</span><br /><Tooltip title={'Sem comunicação há ' + dias + ' dia(s)'}><BlockIcon className={'icon-status-coleta5'} /></Tooltip> <Typography style={{ fontSize: "9px", marginTop: "0px" }}>SEM CONEXÃO</Typography></div>
        }
        switch (status) {
            case 1: return <div><span style={{ fontSize: "10px", }}>{data}</span><br /><HelpIcon className={'icon-status-coleta' + status} /><Typography style={{ fontSize: "9px", marginTop: "0px" }}>{hora}</Typography></div>

            case 2: return <div><span style={{ fontSize: "10px", }}>{data}</span><br /><PowerSettingsNewIcon className={'icon-status-coleta' + status} /><Typography style={{ fontSize: "9px", marginTop: "0px" }}>{hora}</Typography></div>

            case 3: return <div><span style={{ fontSize: "10px", }}>{data}</span><br /><SettingsPowerIcon className={'icon-status-coleta' + status} /><Typography style={{ fontSize: "9px", marginTop: "0px" }}>{hora}</Typography></div>

            case 4: return <div><span style={{ fontSize: "10px", }}>{data}</span><br /><PrintIcon className={'icon-status-coleta' + status} /><Typography style={{ fontSize: "9px", marginTop: "0px" }}>{hora}</Typography></div>

            case 5: return <div><span style={{ fontSize: "10px", }}>{data}</span><br /><ReportProblemIcon className={'icon-status-coleta' + status} /><Typography style={{ fontSize: "9px", marginTop: "0px" }}>{hora}</Typography></div>

            default: return <div><span style={{ fontSize: "10px", }}>{data}</span><br /><HelpIcon className={'icon-status-coleta'} /><Typography style={{ fontSize: "9px", marginTop: "0px" }}>{hora}</Typography></div>
        }

    }
    
    const renderLinhaUltimasImpressoes = (log, i) => {
        return <div id={'IMP+i'} style={{ padding: "5px", paddingLeft: "10px", paddingRight: "10px", display: "flex", fontSize: "12px", width: "100%", color: "#444", opacity: ((15 - i) * 0.15) }}>
            <div style={{ width: "5%", textAlign: "center" }}><PrintIcon style={{ color: "#555" }} /></div>
            <div style={{ width: "20%", textAlign: "center" }}>{log.dataimpressao + ' ' + log.horaimpressao}</div>
            <div style={{ width: "40%" }}>{log.hostname + '\\' + log.nomefila}</div>
            <div style={{ width: "25%" }}>{log.username}</div>
            <div style={{ width: "10%", textAlign: "center" }}>{log.paginas}</div>

        </div>

    }

    const renderCardsUltimasColetas = (coleta, i) => {
        let valor = ((10 - i) * 0.12)
        return <div className='card-coleta-dash'>
            <div style={{ width: "20%", textAlign: "center", opacity: valor }}>
                {
                    renderStatus(coleta.statussistema, coleta.datacoleta, coleta.horacoleta.substr(0, 8), coleta.dias)
                }
            </div>
            <div style={{ width: "80%", opacity: valor }}>
                <div style={{ width: "100%" }} className='texto-limitado' ><Typography variant='caption' style={{ width: "100%" }}>{coleta.razaosocial}</Typography></div>
                <div style={{ width: "100%" }} className='texto-limitado' ><Typography variant='caption' style={{ width: "100%" }}>{coleta.numeroserie + ' - ' + coleta.modelo}</Typography></div>
                <div style={{ width: "100%" }} className='texto-limitado' ><Typography variant='caption' style={{ width: "100%" }}>{coleta.enderecoip}</Typography></div>
            </div>

        </div>

    }
    return <Principal full={'div-dashboard-full'} atualizar={loading} >
        <br />
        <div id='div-dashboard-container' >
            <div className='container-grafico'>
                <div className='container-status-dca' style={{ width: '50%' }} >

                    <Typography variant='caption'style={{fontSize:'15px'}}><strong>Impacto Ambiental do Mês</strong></Typography>
                    <Divider />
                    <div>

                        <Typography variant='caption' style={{ fontSize: "30px" }}>{dataImpactoAmbiental.totalImpressoess}</Typography>
                        <Typography variant='caption'> Páginas/Mês</Typography>
                    </div>
                    <div style={{ display: "flex" }}>

                        <div style={{ width: "25%" }}><p />
                            <Typography variant='subtitle2' style={{ fontSize: "9px" }}><strong>Árvores</strong></Typography><p />
                            <img src={ImgArvore} style={{ width: "40%" }} />
                            <Typography variant='subtitle2' style={{ fontSize: "13px" }}><strong>{dataImpactoAmbiental.arvores} Un.</strong></Typography>
                        </div>
                        <div style={{ width: "25%" }}><p />
                            <Typography variant='subtitle2' style={{ fontSize: "9px" }}><strong>Co2</strong></Typography><p />
                            <img src={ImgCo2} style={{ width: "40%" }} />
                            <Typography variant='subtitle2' style={{ fontSize: "13px" }}><strong>{dataImpactoAmbiental.co2} Ton.</strong></Typography>
                        </div>
                        <div style={{ width: "25%" }}><p />
                            <Typography variant='subtitle2' style={{ fontSize: "9px" }}><strong>Energia</strong></Typography><p />
                            <img src={ImgEnergia} style={{ width: "40%" }} />
                            <Typography variant='subtitle2' style={{ fontSize: "13px" }}><strong>{dataImpactoAmbiental.energia} kW.</strong></Typography>
                        </div>
                        <div style={{ width: "25%" }}> <p />
                            <Typography variant='subtitle2' style={{ fontSize: "9px" }}><strong>Água</strong></Typography><p />
                            <img src={ImgAgua} style={{ width: "40%" }} />
                            <Typography variant='subtitle2' style={{ fontSize: "13px" }} ><strong>{dataImpactoAmbiental.agua} Lit.</strong></Typography>
                        </div>
                    </div>

                </div>


                <div className='container-status-dca' style={{ width: '40%' }}   >
                    <Typography variant='caption'style={{fontSize:'15px'}}><strong>Top 5 Impressoras</strong></Typography>
                    <Divider />

                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ width: '50%' }}>
                            <GraficoTop5Imp data={dataTop5Impressoras} />
                        </div>

                        <div style={{ width: '50%', padding: 20 }}>
                            {
                                dataTop5Impressoras && dataTop5Impressoras.map(function (data, i) {
                                    return <Typography variant='subtitle2' style={{ fontSize: '9px', textAlign: 'left' }} ><strong>{i + 1 + 'º - ' + data.name}</strong></Typography>
                                })
                            }
                        </div>
                    </div>
                </div>



                <div className='container-status-dca' style={{ width: '40%' }}   >
                    <Typography variant='caption' style={{fontSize:'15px'}}><strong>Top 5 Usuários</strong></Typography>
                    <Divider />

                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ width: '50%' }}>
                            <GraficoTop5User data={dataTop5Usuarios} />
                        </div>

                        <div style={{ width: '50%', padding: 20 }}>
                            {
                                dataTop5Usuarios && dataTop5Usuarios.map(function (data, i) {
                                    return <Typography variant='subtitle2' style={{ fontSize: '10px', textAlign: 'left' }} ><strong>{i + 1 + 'º - ' + data.name}</strong></Typography>
                                })
                            }
                        </div>

                    </div>


                </div>

            </div>
        </div>
        <br />
        <br />
        <div style={{ display: "flex", paddingLeft: "21px" }}>
            <div style={{ width: "68%", padding: "5px" }}>

                <Typography variant='subtitle1'>Últimos trabalhos de impressão</Typography>
                <div style={{ width: "100%", }}>
                    <div style={{ padding: "5px", paddingLeft: "10px", paddingRight: "10px", display: "flex", fontSize: "11px", width: "100%", }}>
                        <div style={{ width: "5%", textAlign: "center" }}>#</div>
                        <div style={{ width: "20%", textAlign: "center" }}>Hora</div>
                        <div style={{ width: "40%" }}>Nome da Fila</div>
                        <div style={{ width: "25%" }}>Usuário</div>
                        <div style={{ width: "10%", textAlign: "center" }}>Páginas</div>
                    </div>
                    {
                        logsImpressao && logsImpressao.map(function (log, i) {
                            return renderLinhaUltimasImpressoes(log, i)
                        })
                    }
                </div>
                <br />
                <br />
                <Typography variant='subtitle1'>Equipamentos com nível de toner baixo </Typography>
                <div className='container-chamados-dashboard'>
                    {
                        dataGraficoColetas && dataGraficoColetas.map(function (coleta, i) {
                            return <React.Fragment><CardEquipamento coleta={coleta} /></React.Fragment>
                        })
                    }
                </div>
                <br />
            </div>
            <div style={{ width: "28%", display: "block", width: "30%", marginLeft: "25px", padding: "10px" }}>
                <div style={{ width: "100%", height: "130px", backgroundColor: "#fff", padding: "5px", textAlign: "center", borderRadius: "5px" }}>
                    <Typography variant='subtitle1'>Produção Páginas Mensal (Mês atual)</Typography>
                    <div style={{ display: "flex", justifyContent: 'space-around' }}>
                        <div style={{ width: "20%", textAlign: "center", padding: "5px" }}>
                            <Typography variant='subtitle1' color='primary' style={{ fontSize: "12px" }}>Mono</Typography>
                            <Typography variant='h5' color='primary'>{dataProducaoPaginas.producaoMono} </Typography>
                        </div>
                        <div style={{ width: "20%", textAlign: "center", padding: "5px" }}>
                            <Typography variant='subtitle1' color='primary' style={{ fontSize: "12px" }}>Color</Typography>
                            <Typography variant='h5' color='primary'>{dataProducaoPaginas.producaoColor}</Typography>
                        </div>
                        <div style={{ width: "20%", textAlign: "center", padding: "5px" }}>
                            <Typography variant='subtitle1' color='primary' style={{ fontSize: "12px" }}>Total</Typography>
                            <Typography variant='h5' color='primary'>{dataProducaoPaginas.producaoTotal}</Typography>
                        </div>
                    </div>
                </div>


                <br />
                <Typography variant='subtitle1'>Últimos equipamentos coletados</Typography>
                {
                    dataHistoricoColetas && dataHistoricoColetas.map(function (coleta, i) {
                        return renderCardsUltimasColetas(coleta, i)
                    })
                }
            </div>

        </div>

    </Principal>
}



export default withRouter(DashboardColetasCliente)