import React, {Component} from 'react';
import  {
  AreaChart, 
  Area, 
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend, 
  ResponsiveContainer
} from 'recharts';



const GraficoLinhasCiano = (props) =>{
    const [data, setData] = React.useState([])
    
    React.useEffect(()=>{
      if(props.serial != ''){
        getData();
       
      }
        
    },[props.serial])


    const getData = () =>{
      props.conexao.get('coletasController/getDataNivelTonerGrafico?numeroserie='+props.serial+'&cor=CIANO').then(response =>{
        if(response.data.status == 'OK'){
          setData(response.data.payload);
        }else{
          setData([]);
        }
      }).catch(error =>{
        setData([]);
      })
    }
    return (
      <ResponsiveContainer width="100%" height={120}>
      <AreaChart  data={data}
      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>     
        <XAxis dataKey="datalog" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
      <Area type="monotone" dataKey="nivel" stroke={"#01579b"} fillOpacity={0.1} fill="#01579b" />
     
    </AreaChart>
  </ResponsiveContainer>
    );
  
}

export default GraficoLinhasCiano;