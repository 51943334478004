import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Checkbox} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../../providers/Conexao'
import Chip from '@material-ui/core/Chip';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ComputerIcon from '@material-ui/icons/Computer';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';

const FilasDeImpressao = (props)=>{
   
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [empresa, setEmpresa] = React.useState(props.location.state.empresa)
  
    const [filasImpressao, setFilasImpressao] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [registros, setRegistros]= React.useState(0);
    const [mostrarDesabilitados, setMostrarDesabilitados]= React.useState('t');

    const refDataInicial = React.useRef();
    const refDataFinal = React.useRef();
    const refNomeFila = React.useRef();
    const refHostName = React.useRef();   
    const refUserName = React.useRef();
    const refNomeDocumento = React.useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(()=>{
       getFilasImpressao()
       // console.log(props.location.state.empresa.idcliente)
        
    },[]);
    useEffect(()=>{
       
    },[page,rowsPerPage ]);

    const getFilasImpressao = () =>{
        setLoading(true)
        Conexao.post('filasImpressaoController/getFilasImpressao',{
            idcliente: empresa.idcliente,
            idusuario: empresa.idusuario,
            idempresa: empresa.idempresa,        
            mostrarDesabilitados : mostrarDesabilitados,
           

        }).then(response=>{
            if(response.data.status =='OK'){
              // console.log(response.data.payload)
               setFilasImpressao(response.data.payload);
              
                
            }else{
                setFilasImpressao([])
              
            }
            setLoading(false)
        }).catch(error=>{setLoading(false)});
    }

    



  

    return <Principal atualizar = {loading}>
        <br />
        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Impressoras</Typography></Grid>            
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
       
        <Grid container spacing={2}>
          
            <Grid item xs={2}>
                <TextField
                    select
                    SelectProps={{native:true}}
                    label='Impressora'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                    onChange={(e)=>setMostrarDesabilitados(e.target.value)}
                >
                   <option value ='t'>Habilitadas</option>
                   <option value = 'f'>Desabilitadas</option>

                </TextField>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    
                    label='Impressora'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                >
                   

                </TextField>
            </Grid>

            <Grid item xs = {5}>
                <TextField
                    
                    label ='Descrição'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}               
                    size ='small'
                    />                   
            </Grid>

            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={getFilasImpressao} ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained'  ><ClearIcon/> Limpar</Button>
            </Grid>
            
        </Grid>


        <br/>
        <div>
        <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={'grafico-coleta-clientes/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente} >
                    Home
                </Link>
            <Typography color="textPrimary">Impressoras</Typography>
            </Breadcrumbs>
        </div>
       <div className='div-form-container'>

        <Table className='table-small' style = {{fontSize:"14px", color:"#444"}}>
            <TableHead>
                <TableCell style={{width:"5%", fontSize:"14px"}}>Habilitar</TableCell>
                <TableCell style={{width:"25%", fontSize:"14px"}}>Impressora</TableCell>
                <TableCell style={{width:"20%", fontSize:"14px"}}>Porta</TableCell>
                <TableCell  style={{width:"20%", fontSize:"14px"}}>Driver</TableCell>
                <TableCell  style={{width:"15%", fontSize:"14px"}}>Hostname</TableCell>
                <TableCell  align='center' style={{width:"10%", fontSize:"14px"}}>Color/Mono</TableCell>
             
            </TableHead>
            <TableBody>
               
                   { filasImpressao && filasImpressao.map(function(fila, i){
                       return <TableRow key={i}>
                        <TableCell style={{ fontSize:"12px"}}><Checkbox checked={fila.ativa == 't'} color='secondary' onClick={()=>{
                            Swal.fire({
                                title :'Printerconnect', 
                                text : 'Deseja habilitar/desabilitar relatórios para esta fila de impressão?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Sim', 
                                showCancelButton : true, 
                                cancelButtonText : 'Não'
                            }).then(resp=>{
                                if(resp.isConfirmed){
                                    let opcao = fila.ativa =='t' ? 'f' : 't'
                                    Conexao.post('filasImpressaoController/updateStatusFilaImpressao', {
                                        idfila : fila.idfila, 
                                        status : opcao
                                    }).then(response=>{
                                        if(response.status == 200 && response.data.status =='OK'){
                                            const temp = filasImpressao;
                                            temp[i].ativa = opcao
                                 
                                            setFilasImpressao(temp.filter(item => item.ativa == mostrarDesabilitados))
                                        }
                                    }).catch(error=>{

                                    })
                                }
                            })
                        }}/></TableCell> 
                        <TableCell style={{ fontSize:"12px"}}><Typography variant='caption' color={'primary'}>{fila.nomefila}</Typography></TableCell>  
                        <TableCell style={{ fontSize:"12px"}}>
                        <Typography  style={{ fontSize:"12px"}} variant='caption' color={'primary'}>{fila.porta.length  > 30 ? fila.porta.substr(0,30) + "...":fila.porta}</Typography><br/>
                        </TableCell>
                        <TableCell style={{ fontSize:"12px"}}><Typography variant='caption' style={{fontSize:"12px"}} color={'primary'}>{fila.drivername.length  > 30 ? fila.drivername.substr(0,30) + "...":fila.drivername}</Typography></TableCell>
                        <TableCell style={{fontSize:"12px"}}><Typography variant='caption' color={'primary'}>{fila.host}</Typography><br/>
                        </TableCell>
                        <TableCell  align='center' style={{ fontSize:"14px"}}></TableCell>
                        
                    </TableRow >
                     
                    
                    })  
                    }
                
                
            </TableBody>
      
        </Table>
                


       </div>
       </Principal>

}


export default withRouter(FilasDeImpressao)

