import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField} from '@material-ui/core'
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'


let contador = -1;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoItemColeta(props) {
  const [open, setOpen] = React.useState(false);
  const [camposDisponiveis, setCamposDisponiveis] = React.useState(false);

  const inicialState = [
        'divider',
          'numeroSerie', 
          'marca', 
          'modelo', 
          'enderecoip', 
          'macaddress',
          'setor',
          'nomeservidor', 
        'divider',
          'codigoErro', 
          'statusSistema', 
          'statusCopiadora', 
          'statusImpressora', 
          'statusScanner', 
          'mensagemPainel',
        'divider', 
          'nivelCiano', 
          'numeroSerieCiano', 
          'contadorCiano',
          'capacidadeTonerCiano', 
          'nivelMagenta', 
          'numeroSerieMagenta',
          'contadorMagenta',
          'capacidadeTonerMagenta', 
          'nivelAmarelo', 
          'numeroSerieAmarelo',
          'contadorAmarelo',
          'capacidadeTonerAmarelo',
          'nivelPreto', 
          'numeroSeriePreto', 
          'contadorPreto',
          'capacidadeTonerPreto', 
        'divider', 
          'contadorUnitLaser', 
          'contadorUnitFusao', 
          'contadorUnitCilindro',
          'nivelCilindroCiano', 
          'contadorCilindroCiano',
          'serialCilindroCiano', 
          'nivelCilindroMagenta', 
          'contadorCilindroMagenta',
          'serialCilindroMagenta', 
          'nivelCilindroAmarelo', 
          'contadorCilindroAmarelo', 
          'serialCilindroAmarelo',
          'nivelCilindroPreto', 
          'contadorCilindroPreto', 
          'serialCilindroPreto',
          'contadorUnitTransferencia',
        'divider', 
          'contadorMonoA4', 
          'contadorMonoA3', 
          'contadorMonoCopia', 
          'contadorMonoPrinter',
          'contadorMonoFax', 
          'contadorMonoOutros',
          'contadorMonoTotal',
          'contadorColorA4', 
          'contadorColorA3', 
          'contadorColorCopia', 
          'contadorColorPrinter', 
          'contadorColorFax', 
          'contadorColorOutros', 
          'contadorColorTotal', 
          'contadorTotalGeral', 
          'contadorMonoScanner', 
          'contadorColorScanner', 
          'contadorTotalScanner', 
          'contadorMetros', 
          'contadorCentimetros', 
          'contadorPolegadas', 
        'divider',
          'Campo Calculado (Formula)', 
          'Campo Virtual (campo não disponivel no DB)', 
        'divider', 
          'Personalizado1', 
          'Personalizado2',
          'Personalizado3', 
          'Personalizado4', 
          'Personalizado5', 
          'Personalizado6', 
          'Personalizado7', 
          'Personalizado8', 
          'Personalizado9', 
          'Personalizado10', 
          'Personalizado11', 
          'Personalizado12', 
          'Personalizado13', 
          'Personalizado14', 
          'Personalizado15', 
          'Personalizado16', 
          'Personalizado17', 
          'Personalizado18', 
          'Personalizado19', 
          'Personalizado20'  ];

  const gruposOption = [
      'Informações Gerais',
        'numeroSerie', 
        'marca', 
        'modelo', 
        'enderecoip', 
        'macaddress',
        'setor',
        'nomeservidor', 
      'Status do Equipamento',
        'codigoErro', 
        'statusSistema', 
        'statusCopiadora', 
        'statusImpressora', 
        'statusScanner', 
        'mensagemPainel',
      'Informações de Suprimento', 
        'nivelCiano', 
        'numeroSerieCiano', 
        'contadorCiano',
        'capacidadeTonerCiano',
        'nivelMagenta', 
        'numeroSerieMagenta',
        'contadorMagenta',
        'capcacidadeTonerMagenta',
        'nivelAmarelo', 
        'numeroSerieAmarelo',
        'contadorAmarelo',
        'capacidadeTonerAmarelo', 
        'nivelPreto', 
        'numeroSeriePreto', 
        'contadorPreto',
        'capacidadeTonerPreto', 
      'Contadores de Parts', 
        'contadorUnitLaser', 
        'contadorUnitFusao', 
        'contadorUnitCilindro', 
        'nivelCilindroCiano', 
        'contadorCilindroCiano',
        'serialCilindroCiano', 
        'nivelCilindroMagenta', 
        'contadorCilindroMagenta',
        'serialCilindroMagenta', 
        'nivelCilindroAmarelo', 
        'contadorCilindroAmarelo', 
        'serialCilindroAmarelo',
        'nivelCilindroPreto', 
        'contadorCilindroPreto', 
        'serialCilindroPreto', 
        'contadorUnitTransferencia',
      'Contadores Gerais', 
        'contadorMonoA4', 
        'contadorMonoA3', 
        'contadorMonoCopia', 
        'contadorMonoPrinter', 
        'contadorMonoFax', 
        'contadorMonoOutros', 
        'contadorMonoTotal',
        'contadorColorA4', 
        'contadorColorA3', 
        'contadorColorCopia', 
        'contadorColorPrinter', 
        'contadorColorFax', 
        'contadorColorOutros', 
        'contadorColorTotal',
        'contadorTotalGeral', 
        'contadorMonoScanner', 
        'contadorColorScanner', 
        'contadorTotalScanner', 
        'contadorMetros', 
        'contadorCentimetros', 
        'contadorPolegadas', 
      'Formulas',
        'campoCalculado', 
        'campoVirtual', 
      'Campos Personalizados (uso geral)', 
        'personalizado1',
        'personalizado2', 
        'personalizado3',
        'personalizado4',
        'personalizado5',
        'personalizado6',
        'personalizado7',
        'personalizado8',
        'personalizado9',
        'personalizado10',
        'personalizado11',
        'personalizado12',
        'personalizado13',
        'personalizado14',
        'personalizado15',
        'personalizado16',
        'personalizado17',
        'personalizado18',
        'personalizado19',
        'personalizado20' ];

  const refNome = React.createRef();
  const refValor = React.createRef();
  const refCalculo = React.createRef();
  const refFormula = React.createRef();
  const refAcentos = React.createRef();
  const refVirtual = React.createRef();
  const refValorVirtual = React.createRef();



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(()=>{
    setCamposDisponiveis(inicialState);
  },[props.modelo.idmodelo])
  React.useEffect(()=>{
    if(!open){
        props.getData();
    }
  },[open])



  return (
    <div>
    
    <Button color ='primary' onClick = {handleClickOpen}><ExtensionTwoToneIcon color ='primary'/> Lançar novo parametro</Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth maxWidth={'md'} open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Novo Parametros (OIDs) de Coleta {props.modelo.descricao}
        </DialogTitle>
        <DialogContent dividers style={{height:"400px"}}>
          <Grid container spacing={2}>
              <Grid item xs = {4}>
                  <TextField  
                    label='Rótulo da informação'
                    fullWidth
                    variant='outlined'
                    InputLabelProps={{shrink : true}}
                    select
                    inputRef={refNome}
                    onChange={(e)=>{
                      if(e.target.value == 'setor'){
                        refValor.current.value = '.1.3.6.1.2.1.1.6.0'
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : 'O parametro será preenchido com o valor padrão para o setor, altere se necessário', 
                          icon:'info', 
                          showConfirmButton: true, 
                          showCancelButton:false
                        })
                      }

                      if(e.target.value == 'numeroSerie'){
                        refValor.current.value = '1.3.6.1.2.1.43.5.1.1.17.1'
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : 'O parametro será preenchido com o valor padrão para o número de série, altere se necessário', 
                          icon:'info', 
                          showConfirmButton: true, 
                          showCancelButton:false
                        })
                      }

                      if(e.target.value == 'statusSistema'){
                        refValor.current.value = '.1.3.6.1.2.1.25.3.2.1.5.1'
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : 'O parametro será preenchido com o valor padrão para o status, altere se necessário', 
                          icon:'info', 
                          showConfirmButton: true, 
                          showCancelButton:false
                        })
                      }


                      


                      
                    }}
                    SelectProps={{native : true}}>
                        <option value = 'F'>Selecione...</option>
                        
                        {
                            camposDisponiveis && camposDisponiveis.map(function(campo, i){
                           
                            return camposDisponiveis[i] =='divider' ? <optgroup label={gruposOption[i]}/>: <option >{camposDisponiveis[i]}</option>
                            })
                        }
                    </TextField>
              </Grid>
              <Grid item xs = {8}>
                  <TextField  
                    label='Parametro (OID) de Coleta'
                    placeholder='OID de coleta para o campo ou rotulo do campo se for Virtual ou Calculado'
                    fullWidth
                    variant='outlined'
                    inputRef={refValor}
                    InputLabelProps={{shrink : true}}
                    />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs = {2}>
                <TextField  
                        label='Campo calculado ?'
                        disabled
                        fullWidth
                        variant='outlined'
                        inputRef={refCalculo}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {10}>
                    <TextField  
                        label='Formula do Cálculo'
                        fullWidth
                        placeholder={'Ex : $dados->dados->contadortotalgeral = $dados->dados->contadormonototal + $dados->dados->contadorcolortotal'}
                        variant='outlined'
                        inputRef={refFormula}
                        multiline
                        rows={6}
                        rowsMax={6}
                        InputLabelProps={{shrink : true}}
                        />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs = {4}>
                <TextField  
                        label='Remover acentos do retorno ?'
                        fullWidth
                        variant='outlined'
                        inputRef={refAcentos}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {4}>
                <TextField  
                        label='Este é um campo virtual ?'
                        disabled
                        fullWidth
                        variant='outlined'
                        inputRef={refVirtual}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {4}>
                <TextField  
                        label='Valor Campo Virtual'
                        disabled={refVirtual.current && refVirtual.current.value != 't'}
                        placeholder='Valor virtual que possa ser usado em calculos'
                        fullWidth
                        variant='outlined'
                        inputRef={refValorVirtual}
                        InputLabelProps={{shrink : true}}
                       
                        />
            </Grid>
        </Grid>
        <br/>
       
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus color='primary' variant='contained' onClick={()=>{
              if(refNome.current.value == 'F' || (refValor.current.value == '' && refFormula.current.value == '') || (refCalculo.current.value =='SIM' && refFormula.current.value == '')){
                Swal.fire({
                    title : 'Printercollect', 
                    text : 'Informe todos os parametros necessários !', 
                    icon : 'error', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                  })
              }else{
              Swal.fire({
                  title : 'Printercollect', 
                  text : 'Deseja gravar as operações ?', 
                  icon : 'question', 
                  showCancelButton : true, 
                  cancelButtonText : 'Cancelar', 
                  showConfirmButton : true, 
                  confirmButtonText : 'Confirmar', 
              }).then(resposta =>{
                  if(resposta.isConfirmed){

                      Conexao.post('parametrosController/insertParametros', {
                          idmodelo : props.modelo.idmodelo, 
                          nome : refNome.current ? refNome.current.value : '', 
                          valor : refValor.current ? refValor.current.value : '', 
                          calculo : refCalculo.current ? refCalculo.current.value : '', 
                          formula : refFormula.current ? refFormula.current.value : '', 
                          acentos : refAcentos.current ? refAcentos.current.value : '', 
                          virtual : refVirtual.current ? refVirtual.current.value : '', 
                          valorvirtual : refValorVirtual.current ? refValorVirtual.current.value : ''
                      }).then(response =>{
                            if(response.data.status == 'OK'){
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Parametro inserido com sucesso !', 
                                    icon : 'success', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }else{
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Não foi possível inserir', 
                                    icon : 'warning', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }
                            setOpen(false)
                      }).catch(error =>{
                        Swal.fire({
                            title : 'Printercollect', 
                            text : 'Não Foi possível inserir', 
                            icon : 'success', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1500
                          })
                          setOpen(false)
                      });
                     
                     
                  }
              })
            }
          }} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
