import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {Typography} from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';




const CardsTop = (props) =>{
    return <div id='card-container-main'>
            <div className='card-container'>
                <div className='card-container-itens'>
                    <div className='card-container-avatar'>
                        <PrintIcon />
                    </div>
                
                </div>
                <Typography variant='h5' color='primary'>Coleta Automática de Contadores</Typography>
                <p>O Printerconnect é capaz de se conectar a qualquer equipamento de impressão que esteja conectado via rede TCP/IP, ele captura informações especificas dos equipamentos.</p>
            </div>


            <div className='card-container'>
                <div className='card-container-itens'>
                    <div className='card-container-avatar'>
                        <DirectionsCarIcon />
                    </div>
                
                </div>
                <Typography variant='h5' color='primary'>Rastreamento de Frota via GPS</Typography>
                <p>Com dispositivo GPS com técnologia OBD-2, nosso módulo de Rastreamento é capaz de entregar uma gestão de frota eficiênte e abastecer o sistema de gestão com informação importantes.</p>
            </div>


            <div className='card-container'>
                <div className='card-container-itens'>
                    <div className='card-container-avatar'>
                        <MonetizationOnIcon />
                    </div>
                
                </div>
                <Typography variant='h5' color='primary'>Bilhetagem e Gestão de Impressão</Typography>
                <p>Monitore a utilização do seu parque de impressão, com o Printerconnect você poderá saber : Quem, onde, quando e o que é impresso.Tenha mais controle e elimine desperdicios.</p>
            </div>



           
    </div>
}


export default CardsTop;