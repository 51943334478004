import React, {useState} from 'react';
import clsx from 'clsx';
import {withRouter} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import {TextField, InputAdornment, Typography} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import LinearProgress from '@material-ui/core/LinearProgress';


var md5 = require('md5');




const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const TemporaryDrawer = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });
  const [loading, setLoading] = React.useState(false);
  const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')
    const [recuperarSenha, setRecuperarSenha] = useState(false)



    

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setRecuperarSenha(false)
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );


  const autenticar = () =>{
    setLoading(true)
    console.log(md5(senha))
    Conexao.post('usuariosController/auth', {
        login : login, 
        senha : md5(senha)
    }).then(response =>{
      
            if(response.data.status == 'OK'){
               
                sessionStorage.setItem('token@printerconnect', response.data.payload.idusuario);
                sessionStorage.setItem('empresa@printerConnect', JSON.stringify(response.data.payload));
                sessionStorage.setItem('empresaAtiva@printerConnect', JSON.stringify(response.data.payload.empresaPadrao));
                props.history.push({pathname : '/home/revenda/'+response.data.payload.idusuario, state : {
                    idempresa : response.data.payload.idusuario, 
                    empresa : response.data.payload,
                    empresaPadrao : response.data.payload.empresaPadrao
                  }})
            }else{
                Swal.fire({
                    title: 'Falha de Autenticação',
                    text: 'Não foi possível autenticar ! Usuário o Senha inválidos !',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                  })
            }
            setLoading(false)
        
    }).catch( error =>{
        
        Swal.fire({
            title: 'Falha de Conexão',
            text: `Não foi possível acessar recurso remoto: ${error}`,
            icon: 'error',
            confirmButtonText: 'OK'
          })
          setLoading(false)
    })
}

  return (
  
      <React.Fragment key={'DRAWER01'}>
          <Button variant='contained'  onClick={toggleDrawer('right', true)} className= 'button-entrar'>Entrar</Button>
          <Drawer anchor={'right'} hideBackdrop open={state['right']} onClose={toggleDrawer('right', false)} elevation={0}>
           <div id='drawer-container' style ={{textAlign:"center"}}>
               <Typography variant='h5'>Acesso Revenda</Typography>

               <br/>
               <Divider/>
               <br/>
               <br/>
               <br/>
           <div>
                    <TextField
                        variant='outlined'
                        label='Login'
                        placeholder='Informe o seu email de acesso'
                        InputLabelProps = {{shrink : true}}
                        fullWidth = {true}
                        onChange = {(e)=>setLogin(e.target.value)}
                        value ={login}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon style = {{color : "#ccc"}} />
                                </InputAdornment>
                            ),
                            }}
                     />
                </div>

                {!recuperarSenha && <div style ={{marginTop:"20px"}}>
                    <TextField
                        variant='outlined'
                        label='Password'
                        placeholder='Informe a sua palavra passe'
                        InputLabelProps = {{shrink : true}}
                        fullWidth = {true}
                        type = 'password'
                        onChange = {(e)=>setSenha(e.target.value)}
                        value ={senha}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon style = {{color : "#ccc"}} />
                                </InputAdornment>
                            ),
                            }}
                     />
                </div>}
                <br/>
                <br/>
                <br/>
                <div style ={{textAlign:"right"}}>
                <div  style ={{textAlign:"left", paddingBottom:"10px"}}>
                {recuperarSenha && <Typography variant = 'caption'>* Será enviado para o email informado (se cadastrado) o link para alteração da senha.</Typography>}
                </div>
                          {recuperarSenha ? <Button variant='contained' color ='primary' onClick={()=>{
                            Swal.fire({
                              title :'Printerconnect', 
                              text : 'Deseja receber o link para recuperação de senha ?', 
                              icon : 'question',                               
                              showCancelButton : true, 
                              cancelButtonText : 'Cancelar', 
                              showConfirmButton : true, 
                              confirmButtonText : 'Enviar'
                            }).then(resposta =>{
                              if(resposta.isConfirmed){
                                setLoading(true)
                                Conexao.post('usuariosController/recuperarSenha', {
                                  login : login
                              }).then(response =>{
                                
                                      if(response.data.status == 'OK'){
                                        Swal.fire({
                                          title: 'Printerconnect',
                                          text: 'Email enviado com sucesso',
                                          icon: 'success',
                                          confirmButtonText: 'OK'
                                        })
                                         
                                      }else{
                                          Swal.fire({
                                              title: 'Printerconnect',
                                              text: 'Não foi possível enviar email',
                                              icon: 'warning',
                                              confirmButtonText: 'OK'
                                            })
                                      }
                                      setLoading(false)
                                  setRecuperarSenha(false)
                              }).catch( error =>{
                                  
                                  Swal.fire({
                                    title: 'Printerconnect',
                                    text: 'Não foi possível enviar email',
                                    icon: 'error',
                                      confirmButtonText: 'OK'
                                    })
                                    setLoading(false)
                              })
                              }
                            })
                          }} fullWidth>Enviar link</Button> : <Button variant='contained' color ='primary' onClick={autenticar} fullWidth>{loading ? 'Autenticando...' : 'Entrar'}</Button> }
                          { loading && <LinearProgress color="secondary" />}
                <br/>
                          {!recuperarSenha ? <Button variant='contained' color ='secondary' onClick={toggleDrawer('right', false)} fullWidth style ={{marginTop:"10px"}}>CANCELAR</Button> : <Button variant='contained' color ='secondary' onClick={()=>setRecuperarSenha(false)} fullWidth style ={{marginTop:"10px"}}>CANCELAR</Button>  }
                <br/>
                <Button variant='outlined' color ='primary' onClick={()=>{
                  props.history.push('/');
                }} fullWidth style ={{marginTop:"10px"}}>SOU UM CLIENTE</Button> 
               <div style ={{marginTop:"10px"}}>
                          {!recuperarSenha && <span className='span-link' onClick = {()=>setRecuperarSenha(true)}>Esqueci a senha</span> }
               </div>
                </div>
                <br/>
            
                
           </div>
          </Drawer>
        </React.Fragment>
 
  );
}

export default withRouter(TemporaryDrawer) 