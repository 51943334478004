import React, {Fragment} from 'react'
import {Typography, Divider} from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';



export default function(props){
    return <footer id='footer-rodape'>
        <div className='footer-container-flex'>
            <div className='div-footer-logo'>
                <Typography variant='h4'>A3M Soluções Digitais</Typography>
                <Typography variant='subtitle1'>Outsourcing de Impressão na era do IoT</Typography>
            
            <div style = {{display:"flex", marginTop:"20px"}}>
            <FacebookIcon style = {{cursor:"pointer"}}/>
            <LinkedInIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
            <InstagramIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
            <TwitterIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
                
            </div>
                
         
            </div>
            <div className='div-footer-texto'> 
            <Typography variant='h5'>Institucional</Typography>
            <Typography variant='subtitle1'>A A3M Soluções digitais é uma empresa de tecnologia e desenvolvimento de sistemas focada em soluções para Outsourcing de Impressão.</Typography>
            <br/>
            <Typography variant='subtitle2'>Rua Narciso Ribeiro, 319</Typography>
            <Typography variant='subtitle2'>CEP 17523-230 Jardim Nacional</Typography>
            <Typography variant='subtitle2'>Marília -  São Paulo </Typography>

            <Typography variant='subtitle2'>(14) 9 9871-7644 e (14) 9 9750-7500 </Typography>
            <Typography variant='subtitle2'>contato@a3msolucoes.com.br</Typography>
            </div>
        </div>
    </footer>
}